import React, { useCallback, useEffect, useState } from 'react'
import {
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { grey } from '@mui/material/colors'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { ReactComponent as PreviewPhoneIcon } from '../../../../assets/images/icons/preview_phone.svg'
import PhoneDialog from '../../../PhonePreview/PhoneDialog'
import AddPointsDialog from './AddPointsDialog'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import GameService from '../../../../services/game.service'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { LevelPlayer, Player } from '../../../../store/Game/types'
import { renderGroupIcon, thousandsSeparator } from '../../../../helpers/utils'
import UserProfileImage from '../../../shared/UserProfileImage'
import { Option } from '../../../../store/types'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'

const Caption = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
})

const Body = styled('div')({
  fontSize: '14px',
  fontWeight: 800,
})

const AddPointsButton = styled('div')({
  fontSize: '16px',
  fontWeight: 800,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
})

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  border: '1px solid #CF0000',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#fff',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#CF0000',
  },
})

type PlayersDrawerProps = {
  level: LevelPlayer | null
  openDrawer: boolean
  toggleDrawer: (
    open: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void
  companies: Option[]
  xpAmount: number
  setRefreshGameData: React.Dispatch<React.SetStateAction<boolean>>
}

const PlayersDrawer: React.FC<PlayersDrawerProps> = ({
  level,
  openDrawer,
  toggleDrawer,
  companies,
  xpAmount,
  setRefreshGameData,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [refreshPlayersData, setRefreshPlayersData] = useState(false)
  const [openPhoneDialog, setPhoneDialogOpen] = useState(false)
  const [openAddPointsDialog, setAddPointsDialogOpen] = useState(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [players, setPlayers] = useState<Player[]>([])
  const [player, setPlayer] = useState<Player | null>(null)

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const playersResponse = await GameService.getPlayers(
        level!.id,
        companies.map((company) => ({ id: company.value })),
        level!.users,
      )

      if (playersResponse.data.users) {
        setPlayers(playersResponse.data.users)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setLoading(false)
    }
  }, [companies, level, t])

  useEffect(() => {
    if (openDrawer && level) {
      fetchData()
    }
  }, [level, openDrawer, fetchData, refreshPlayersData])

  const handlePhoneDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setPhoneDialogOpen(true)
  }

  const handlePhoneDialogClose = () => {
    setPhoneDialogOpen(false)
  }

  const handleAddPointsDialogClickOpen = (
    disabled: boolean,
    player: Player,
  ) => {
    if (!disabled) {
      setPlayer(player)
      setAddPointsDialogOpen(true)
    }
  }

  const handleAddPointsDialogClose = (refresh: boolean = false) => {
    setAddPointsDialogOpen(false)
    if (refresh) {
      setRefreshGameData((prevState) => !prevState)
      setRefreshPlayersData((prevState) => !prevState)
    }
  }

  const renderPlayer = (player: Player) => {
    return (
      <div
        style={{
          padding: '20px 20px 0 20px',
          borderTop: '1px solid #e6e6e6',
        }}
        key={player.id}
      >
        <Stack flexDirection="row" paddingBottom={'20px'}>
          <UserProfileImage
            userId={player.id}
            firstname={player.firstname}
            lastname={player.lastname}
            style={{
              width: 50,
              height: 50,
            }}
          />
          <Stack justifyContent="center" style={{ marginLeft: '15px' }}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              component="div"
              lineHeight={'normal'}
            >
              {player.firstname} {player.lastname}{' '}
              {player.userCentralId ? `(${player.userCentralId})` : ''}
            </Typography>
            <Typography variant="caption" color={grey[500]}>
              {t('pages.game.company')}: {player.companyName}
            </Typography>
          </Stack>
          {player.awardGroup && (
            <Stack
              display="flex"
              flexDirection="row"
              ml="auto"
              alignItems="center"
            >
              {renderGroupIcon(player.awardGroup)}
              <div
                style={{ marginLeft: 5, fontSize: 13, lineHeight: 'normal' }}
              >
                <strong>
                  {t('pages.game.group')} {player.awardGroup}
                </strong>
                <br />
                <small>{player.awardReceiveDate?.substring(0, 16)}</small>
              </div>
            </Stack>
          )}
        </Stack>
        <Grid
          container
          spacing={2}
          paddingBottom={'10px'}
          display={'flex'}
          alignItems={'flex-end'}
        >
          {!level && (
            <Grid item xs={2}>
              <Caption>{t('pages.game.level')}:</Caption>
              <Body>{player.levelId}</Body>
            </Grid>
          )}
          <Grid item xs={!level ? 8 : 9}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Body>
                  {thousandsSeparator(player.xpAmount)}{' '}
                  {t('pages.game.xpPoints')}
                </Body>
              </Grid>
              <Grid item xs={7} paddingTop={'13px !important'}>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    player.levelPercentage >= 100 ? 100 : player.levelPercentage
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Caption>{t('pages.game.rankPlace')}:</Caption>
            <Body>{player.rankPlace}</Body>
          </Grid>
          {!level && <Grid item xs={1}></Grid>}
        </Grid>
        {player.previewEnabled && (
          <Stack display={'flex'} flexDirection={'row'} paddingBottom={'10px'}>
            <AddPointsButton
              onClick={() =>
                handleAddPointsDialogClickOpen(
                  xpAmount > 0 && player.previewEnabled ? false : true,
                  player,
                )
              }
              style={{
                opacity: xpAmount > 0 && player.previewEnabled ? 1 : 0.3,
              }}
            >
              <IconButton
                style={{
                  height: '32px',
                  width: '32px',
                  background: '#FFC20F',
                  marginRight: '10px',
                }}
              >
                <AddOutlinedIcon htmlColor="black" />
              </IconButton>
              {t('pages.game.addXpPoints')}
            </AddPointsButton>
            <IconButton
              style={{
                marginLeft: 'auto',
                height: '50px',
                width: '50px',
                opacity: player.previewEnabled ? 1 : 0.3,
              }}
              onClick={() => handlePhoneDialogClickOpen(player.id)}
              disabled={!player.previewEnabled}
            >
              <PreviewPhoneIcon />
            </IconButton>
          </Stack>
        )}
      </div>
    )
  }

  const renderXpMaxThreshold = (
    xpMinThreshold: number,
    xpMaxThreshold: number,
  ) => {
    return xpMaxThreshold === 0 && xpMaxThreshold < xpMinThreshold ? (
      <AllInclusiveIcon />
    ) : (
      thousandsSeparator(xpMaxThreshold)
    )
  }

  return (
    <>
      <Drawer
        container={document.getElementsByClassName('drawer-container')[0]}
        anchor={'left'}
        open={openDrawer}
        onClose={toggleDrawer(false)}
        ModalProps={{
          style: {
            position: 'absolute',
            zIndex: 1100,
          },
        }}
        PaperProps={{
          elevation: 0,
          style: {
            position: 'absolute',
            width: 460,
            background: '#f1f1f1',
          },
        }}
      >
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              padding={'20px'}
            >
              <Typography variant="h5" component="div">
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <strong>
                    {t('pages.game.level')} {level?.levelNumber}
                  </strong>
                  &nbsp;(
                  {thousandsSeparator(players[0].xpMinThreshold)}&nbsp;-&nbsp;
                  {renderXpMaxThreshold(
                    players[0].xpMinThreshold,
                    players[0].xpMaxThreshold,
                  )}
                  )
                </Stack>
              </Typography>
              <IconButton aria-label="close" onClick={toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            {players.map((player) => renderPlayer(player))}
          </>
        )}
      </Drawer>
      {userId && (
        <PhoneDialog
          open={openPhoneDialog}
          handleClose={handlePhoneDialogClose}
          userId={userId}
        />
      )}
      {player && (
        <AddPointsDialog
          open={openAddPointsDialog}
          handleClose={handleAddPointsDialogClose}
          xpAmount={xpAmount}
          player={player}
        />
      )}
    </>
  )
}

export default PlayersDrawer

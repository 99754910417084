import React, { HTMLAttributes, useEffect, useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'
import {
  FormControl,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'react-multi-select-component'
import { Option } from '../../../store/types'
import CompanyService from '../../../services/company.service'
import GameService from '../../../services/game.service'
import { errorHandler } from '../../../helpers/errorHandler'
import LoadingSpinner from '../../shared/LoadingSpinner'
import PlayersDrawer from './partials/PlayersDrawer'
import { LevelPlayer, GameData } from '../../../store/Game/types'
import { thousandsSeparator } from '../../../helpers/utils'
import {
  MAPS_DATA,
  MAP_IMG_STYLE,
  MAP_STYLE,
  NUM_OF_LEVELS_PER_MAP,
  PLOT_DATA,
} from './GameConfig'
import PrimaryButton from '../../../styles/Buttons/PrimaryButton'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import PlotDialog from './partials/PlotDialog'

type PlayersDotProps = HTMLAttributes<HTMLDivElement> & {
  top: number
  left: number
}

const PlayersDot = styled('div')<PlayersDotProps>(({ top, left }) => ({
  position: 'absolute',
  top: `${top}px`,
  left: `${left}px`,
  background: '#fff',
  border: '5px solid #C40000',
  borderRadius: '50%',
  width: 55,
  height: 55,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  fontWeight: 800,
  fontSize: '18px',
}))

const Game = () => {
  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const [refreshGameData, setRefreshGameData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [companies, setCompanies] = useState<Option[]>([])
  const [level, setLevel] = useState<LevelPlayer | null>(null)
  const [xpAmount, setXpAmount] = useState<number>(0)
  const [mapsData, setMapsData] = useState<GameData[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [openPlotDialog, setPlotDialogOpen] = useState(false)
  const [plotImage, setPlotImage] = useState<string | null>(null)
  const [plotTitle, setPlotTitle] = useState<string | null>(null)
  const [plotContent, setPlotContent] = useState<string | null>(null)

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  const handlePlotDialogClickOpen = (
    image: string | null,
    title: string | null,
    content: string | null,
  ) => {
    setPlotImage(image)
    setPlotTitle(title)
    setPlotContent(content)
    setPlotDialogOpen(true)
  }

  const handlePlotDialogClose = () => {
    setPlotDialogOpen(false)
  }

  const generateMapsData = (numOfMaps: number, numOfLevels: number) => {
    const data: GameData[] = []
    for (let index = 0; index < numOfMaps; index++) {
      const levelPositions = []
      for (let j = 0; j < numOfLevels; j++) {
        levelPositions.push({
          top: 330,
          left: 70 * j + 170,
        })
      }
      data.push({
        mapSrc: require(`../../../assets/images/game/map/${index + 1}.png`),
        levelPositions,
        levels: [],
      })
    }
    return data
  }

  const fetchLevelPlayers = useCallback(
    async (companies: { id: number }[], search: string) => {
      try {
        const levelPlayersResponse = await GameService.getLevelPlayers(
          companies,
          search,
        )

        if (levelPlayersResponse.data.levels) {
          setXpAmount(levelPlayersResponse.data.xpAmount)
          let gameData: GameData[] = []
          if (MAPS_DATA.length > 0) {
            gameData = MAPS_DATA
          } else {
            gameData = generateMapsData(6, NUM_OF_LEVELS_PER_MAP)
          }
          const gameDataWithLevels = gameData.map((data, i) => ({
            ...data,
            levels: levelPlayersResponse.data.levels.slice(
              i * NUM_OF_LEVELS_PER_MAP,
              i * NUM_OF_LEVELS_PER_MAP + NUM_OF_LEVELS_PER_MAP,
            ),
          }))
          setMapsData(gameDataWithLevels)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    },
    [t],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setSelectedCompanies(multiSelectOptions)
        }

        fetchLevelPlayers(
          companyListResponse.data.companies.map((company) => ({
            id: company.companyId,
          })),
          searchText,
        )
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, currentUser, fetchLevelPlayers, refreshGameData]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedCompaniesChange = async (companies: Option[]) => {
    setSelectedCompanies(companies)
    fetchLevelPlayers(
      companies.map((company) => ({
        id: company.value,
      })),
      searchText,
    )
  }

  if (!currentUser || !isLoggedIn) {
    return <Redirect to="/login" />
  }

  const handleLevelClick = (level: LevelPlayer) => {
    setLevel(level)
    setOpenDrawer(true)
  }

  const renderMap = (
    index: number,
    mapSrc: string,
    levelPositions: { top: number; left: number }[],
    levels: LevelPlayer[],
  ) => {
    return (
      <div style={MAP_STYLE} key={`map-${index}`}>
        {levels.map(
          (level, i) =>
            level &&
            level.usersCount > 0 && (
              <Tooltip
                title={`${t('pages.game.level')} ${level.levelNumber}`}
                arrow
                placement="top"
                key={`player-tooltip-${index}-${i}`}
              >
                <PlayersDot
                  top={levelPositions[i].top}
                  left={levelPositions[i].left}
                  onClick={() => handleLevelClick(level)}
                >
                  {level.usersCount}
                </PlayersDot>
              </Tooltip>
            ),
        )}
        {PLOT_DATA[index] && (
          <PrimaryButton
            style={PLOT_DATA[index].style}
            onClick={() =>
              handlePlotDialogClickOpen(
                PLOT_DATA[index].image,
                PLOT_DATA[index].title,
                PLOT_DATA[index].content,
              )
            }
          >
            <SearchIcon sx={{ mr: 1 }} /> {t('pages.game.plot')}
            {index}
          </PrimaryButton>
        )}
        <img src={mapSrc} alt="" style={MAP_IMG_STYLE} />
      </div>
    )
  }

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpenDrawer(open)
    }

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      submitSearch(searchText)
    }
  }

  const onSearchChange = (event: { target: { value: string } }) => {
    setSearchText(event.target.value)
  }

  const submitSearch = (searchValue: string) => {
    setSearchText(searchValue)
    fetchLevelPlayers(
      companies.map((company) => ({
        id: company.value,
      })),
      searchValue,
    )
  }

  const clearSearch = () => {
    setSearchText('')
    fetchLevelPlayers(
      companies.map((company) => ({
        id: company.value,
      })),
      '',
    )
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Grid
          container
          className="drawer-container"
          position={'relative'}
          height={'calc(100% - 64px)'}
          marginTop={'-36px'}
          alignContent={'flex-start'}
        >
          <Grid item xs={12} p={3}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="h5" component="div" fontWeight={800}>
                {t('pages.game.title')}
              </Typography>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
              >
                <strong>{t('pages.game.pointsToBeAwarded')}:</strong>
                <Typography
                  variant="h5"
                  component="span"
                  fontWeight={800}
                  mx={1}
                >
                  {thousandsSeparator(xpAmount)}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} px={3} pb={4}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            >
              <FormControl size="small" sx={{ minWidth: 180, zIndex: 6 }}>
                <small>{t('pages.game.filterByCompany')}</small>
                <MultiSelect
                  options={companies}
                  value={selectedCompanies}
                  onChange={handleSelectedCompaniesChange}
                  labelledBy={t('multiSelect.labelledBy')}
                  overrideStrings={{
                    allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
                    clearSearch: t('multiSelect.clearSearch'),
                    noOptions: t('multiSelect.noOptions'),
                    search: t('multiSelect.search'),
                    selectAll: t('multiSelect.selectAll'),
                    selectAllFiltered: t('multiSelect.selectAllFiltered'),
                    selectSomeItems: t('multiSelect.selectSomeItems'),
                  }}
                  valueRenderer={customValueRenderer}
                />
              </FormControl>
              <div>
                <TextField
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={onSearchChange}
                  placeholder={t('pages.stores.table.search')}
                  onKeyDown={onSearchKeyDown}
                  InputProps={{
                    style: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    } as React.CSSProperties,
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{
                          visibility: searchText ? 'visible' : 'hidden',
                        }}
                        onClick={clearSearch}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  inputProps={{ maxLength: 100 }}
                />
                <PrimaryButton
                  onClick={() => submitSearch(searchText)}
                  style={{
                    padding: '7px 5px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    height: '40px',
                  }}
                >
                  {t('common.search')}
                </PrimaryButton>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} px={3}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              width={'100%'}
              overflow={'scroll'}
            >
              {mapsData.map((m, i) =>
                renderMap(i, m.mapSrc, m.levelPositions, m.levels),
              )}
            </Stack>
          </Grid>
          <PlayersDrawer
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawer}
            level={level}
            companies={selectedCompanies}
            xpAmount={xpAmount}
            setRefreshGameData={setRefreshGameData}
          />
          <PlotDialog
            open={openPlotDialog}
            handleClose={handlePlotDialogClose}
            image={plotImage}
            title={plotTitle}
            content={plotContent}
          />
        </Grid>
      )}
    </>
  )
}

export default Game

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import LoyaltyUserService from '../../../../services/loyaltyUser.service'
import { toast } from 'react-toastify'
import { LoyaltyUser } from '../../../../store/LoyaltyUser/types'

type LoyaltyUserDeactivationDialogProps = {
  participants: Pick<LoyaltyUser, 'participantId'>[]
  open: boolean
  handleClose: (refresh: boolean) => void
}

const LoyaltyUserDeactivationDialog: React.FunctionComponent<
  LoyaltyUserDeactivationDialogProps
> = ({ participants, open, handleClose }) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState<boolean>(false)

  const onConfirm = async () => {
    try {
      setSaving(true)
      const participantDeactivateResponse =
        await LoyaltyUserService.userDeactivate(
          participants.map((participant) => ({
            id: participant.participantId,
          })),
        )

      if (participantDeactivateResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  const renderParticipantIds = (
    participants: Pick<LoyaltyUser, 'participantId'>[],
  ) => participants.map((participant) => participant.participantId).join(', ')

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.loyaltyUsersprt.loyaltyUserDeactivationDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {t('pages.loyaltyUsersprt.loyaltyUserDeactivationDialog.forUsers')}:{' '}
            {renderParticipantIds(participants)}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
            }}
            disabled={saving}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onConfirm} disabled={saving}>
            {t('pages.loyaltyUsersprt.loyaltyUserDeactivationDialog.submit')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default LoyaltyUserDeactivationDialog

import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { Stack, Alert, Typography, ButtonGroup, Button } from '@mui/material'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  wrapSvgText,
  getSVGString,
  svgString2Image,
  thousandsSeparator,
} from '../../../../helpers/utils'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import HomeService from '../../../../services/home.service'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './CompanyXpRankingChart.css'
import { CompanyXpRanking } from '../../../../store/Home/types'

type CompanyXpRankingChartProps = {}

const CompanyXpRankingChart: React.FunctionComponent<
  CompanyXpRankingChartProps
> = () => {
  const { t } = useTranslation()
  const chartEl = useRef<SVGSVGElement>(null)
  const [svgNode, setSvgNode] = useState<SVGSVGElement | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [chartData, setChartData] = useState<CompanyXpRanking[]>([])
  const [width, setWidth] = useState<number>(1300)

  const height = 300
  const barColors = '#4e79a7';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyXpRankingResponse = await HomeService.getCompanyXpRanking()
        if (companyXpRankingResponse.data.companies) {
          const chartData = companyXpRankingResponse.data.companies

          setChartData(chartData)
          setWidth(chartData.length * 80)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  useEffect(() => {
    if (chartData.length > 0) {
      setTimeout(() => {
        const margin = { top: 50, right: 20, bottom: 40, left: 20 }

        const x = (d: CompanyXpRanking) => d.companyName // given d in data, returns the (ordinal) x-value
        const y = (d: CompanyXpRanking) => d.xpAvValue // given d in data, returns the (quantitative) y-value

        // Compute values.
        const X = d3.map(chartData, x)
        const Y = d3.map(chartData, y)

        // Compute default domains, and unique the x- and z-domains.
        const xDomain = new d3.InternSet(X)
        const yDomain = [0, d3.max(Y) ?? 0]

        const xRange = [margin.left, width - margin.right]
        const yRange = [height - margin.bottom, margin.top]
        const xPadding = 0.5

        const yType = d3.scaleLinear

        const xScale = d3.scaleBand(xDomain, xRange).paddingInner(xPadding)
        const yScale = yType(yDomain, yRange)
        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0)

        // Omit any data not present in both the x- and z-domain.
        const I = d3.range(X.length).filter((i) => xDomain.has(X[i]))

        // Compute titles.
        const formatValue = yScale.tickFormat(100)
        const title = (i: number) => `${X[i]}\n${formatValue(Y[i])}`

        const svg = d3.select(chartEl.current)

        svg
          .attr('width', width)
          .attr('height', height)
          .attr('viewBox', `0 0 ${width} ${height}`)
          .attr('style', 'height: auto; height: intrinsic;')

        svg.selectAll('*').remove()

        svg
          .append('g')
          .attr('transform', `translate(${margin.left},0)`)
          .call((g) => g.select('.domain').remove())

        const bar = svg
          .append('g')
          .selectAll('rect')
          .data(I)
          .join('rect')
          .attr('x', (i) => xScale(X[i]) ?? 0)
          .attr('y', (i) => yScale(Y[i]))
          .attr('width', xScale.bandwidth())
          .attr('height', (i) => yScale(0) - yScale(Y[i]))
          .attr('fill', barColors)
          .attr('rx', 2)
          .attr('ry', 2)

        if (title) bar.append('title').text(title)

        svg
          .append('g')
          .attr('class', 'bar-label-group')
          .selectAll('.bar-label')
          .data(I)
          .join('text')
          .attr('width', xScale.bandwidth())
          .attr(
            'transform',
            (d, i) =>
              `translate(${(xScale(X[i]) ?? 0) + xScale.bandwidth() / 2},${
                yScale(Y[i]) - 10
              })`,
          )
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'middle')
          .text((i) => `${thousandsSeparator(Y[i])}`)

        svg
          .append('g')
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .call(xAxis)
          .selectAll('.tick text')
          .call((g) => wrapSvgText(g, xScale.bandwidth(), d3))

        svg.selectAll('.tick line').attr('y2', '0')

        setSvgNode(svg.node())
      }, 0)
    }
  }, [chartData, width])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div
          style={{
            background: '#f1f1f1',
            borderRadius: '3px',
            margin: '0 20px',
            padding: '15px 20px',
            height: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                fontWeight="bold"
              >
                {t('pages.home.companyXpRankingChart.title')}
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                component="div"
                lineHeight="normal"
              >
                <InfoOutlinedIcon
                  sx={{ width: 14, height: 14, marginRight: 1 }}
                />
                {t('pages.home.companyXpRankingChart.subtitle')}
              </Typography>
            </div>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
              color='primary'
              sx={{ minWidth: '200px', marginLeft: 5 }}
            >
              <Button sx={{textTransform: 'capitalize'}} variant="contained">{t('pages.home.companyXpRankingChart.avarageXp')}</Button>
              <Button sx={{textTransform: 'capitalize'}} disabled>{t('pages.home.companyXpRankingChart.gameFinal')}</Button>
            </ButtonGroup>
            {chartData.length > 0 && (
              <SecondaryButton
                sx={{ minWidth: '270px', ml: 2 }}
                size="small"
                onClick={() => {
                  var svgString = getSVGString(svgNode)
                  svgString2Image(svgString, 2 * width, 2 * height, 'png', save) // passes Blob and filesize String to the callback

                  function save(dataBlob: any, filesize: any) {
                    saveAs(
                      dataBlob,
                      `${t(
                        'pages.home.companyXpRankingChart.exportFilename',
                      )}.png`,
                    ) // FileSaver.js function
                  }
                }}
              >
                {t('pages.home.companyXpRankingChart.exportChartToPNG')}
              </SecondaryButton>
            )}
          </Stack>
          {chartData.length === 0 && (
            <Alert severity="info" variant="filled">
              {t('pages.home.companyXpRankingChart.noDataToDisplay')}
            </Alert>
          )}
          {chartData.length > 0 && (
            <div style={{ overflowY: 'auto' }}>
              <svg ref={chartEl} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CompanyXpRankingChart

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  Grid,
  IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import ClearIcon from '@mui/icons-material/Clear'
import LoyaltyUserService from '../../../../services/loyaltyUser.service'
import { LoyaltyUserDetails } from '../../../../store/LoyaltyUser/types'

type LoyaltyUserAddDialogProps = {
  participantId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const LoyaltyUserAddDialog: React.FunctionComponent<
  LoyaltyUserAddDialogProps
> = ({ participantId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [participantDetails, setParticipantDetails] =
    useState<LoyaltyUserDetails | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const participantDetailsResponse =
          await LoyaltyUserService.getParticipantDetails(participantId)
        setParticipantDetails(participantDetailsResponse.data)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, participantId])

  const rendeParticipantDetailsRow = (
    label: string,
    value: JSX.Element | JSX.Element[] | string | number,
  ) => {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={4} textAlign="right">
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bold"
            style={{ wordBreak: 'break-all' }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" gutterBottom>
            {value}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleClose(false)}
    >
      {loading && <LoadingSpinner />}
      {!loading && participantDetails && (
        <>
          <DialogTitle style={{ height: '90px' }}>
            <Stack flexDirection="row">
              <Stack justifyContent="center" style={{ marginLeft: '15px' }}>
                <Typography variant="caption">
                  {t(
                    'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.participantId',
                  )}
                  : {participantId}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  component="div"
                >
                  {participantDetails.firstname} {participantDetails.lastname}
                </Typography>
              </Stack>
              <IconButton
                style={{ marginLeft: 'auto', height: '50px', width: '50px' }}
                onClick={() => handleClose(false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.participantCentralId',
                ),
                participantDetails.participantCentralId,
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.groupName',
                ),
                participantDetails.groupName,
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.storeCentralId',
                ),
                participantDetails.storeCentralId,
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.storeName',
                ),
                participantDetails.storeName,
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.firstname',
                ),
                participantDetails.firstname
                  ? participantDetails.firstname
                  : '-',
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.lastname',
                ),
                participantDetails.lastname ? participantDetails.lastname : '-',
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.email',
                ),
                participantDetails.email ? participantDetails.email : '-',
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.username',
                ),
                participantDetails.username,
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.mobile',
                ),
                participantDetails.mobile ? participantDetails.mobile : '-',
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.description',
                ),
                participantDetails.description
                  ? participantDetails.description
                  : '-',
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.additionalDescription',
                ),
                participantDetails.additionalDescription
                  ? participantDetails.additionalDescription
                  : '-',
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.lastLoginDate',
                ),
                participantDetails.lastLoginDate
                  ? participantDetails.lastLoginDate
                  : '-',
              )}
              {rendeParticipantDetailsRow(
                t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.isVisible',
                ),
                participantDetails.isVisible ? t('common.yes') : t('common.no'),
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" justifyContent="flex-end" width="100%">
              <SecondaryButton onClick={() => handleClose(false)}>
                {t('common.close')}
              </SecondaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default LoyaltyUserAddDialog

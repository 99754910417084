import { FormError } from '../types'

export const enum LoyaltyUserTypeCode {
  PRT = 'PRT',
}

export type LoyaltyUser = {
  participantId: number
  participantCentralId: string
  isVisible: boolean | string | null
  isActive: boolean | string | null
  lastLoginDate: string | null
  modified: string
  created: string
  typeId: number
  typeName: string
  typeCode: LoyaltyUserTypeCode
  contactId: number
  firstname: string | null
  lastname: string | null
  mobile: string | null
  email: string | null
  description: string | null
  additionalDescription: string | null
  username: string
  storeId: number | null
  storeCentralId: string | null
  storeFullName: string | null
  companyId: number | null
  companyName: string | null
  userId: number | null
  userCentralId: string | null
  userUsername: string | null
}

export type LoyaltyUsersResponse = {
  data: {
    participants: LoyaltyUser[]
    totalCount: number
  }
}

export type LoyaltyAddUserParams = {
  groupId: number
  centralId: string
  storeCentralId: string
  firstname: string | null
  lastname: string | null
  email: string | null
  username: string
  description: string | null
  additionalDescription: string | null
  participantTypeCode: LoyaltyUserTypeCode
  password: string | null
  generatePassword: boolean
}

export type LoyaltyAddUserErrors = {
  groupId: FormError
  centralId: FormError
  storeCentralId: FormError
  firstname: FormError
  lastname: FormError
  email: FormError
  username: FormError
  description: FormError
  additionalDescription: FormError
  password: FormError
  generatePassword: FormError
  chooseWayToGeneratePassword: FormError
}

export type LoyaltyAddUserResponse = {
  data: {
    success: boolean
  }
}

export type Group = {
  id: number
  name: string
  imageId: number | null
  centralId: string | null
  description: string | null
}

export type LoyaltyUserGroupsResponse = {
  data: {
    groups: Group[]
  }
}

export type LoyaltyUserDetails = {
  participantId: number
  participantCentralId: string
  participantTypeId: number
  participantTypeName: string
  participantTypeCode: string
  contactId: number
  firstname: string | null
  lastname: string | null
  username: string
  mobile: string | null
  email: string | null
  description: string | null
  additionalDescription: string | null
  isVisible: boolean
  lastLoginDate: string | null
  groupId: number
  groupName: string
  storeId: number
  storeCentralId: string
  storeName: string
}

export type LoyaltyUserDetailsResponse = {
  data: LoyaltyUserDetails
}

export type LoyaltyEditUserParams = {
  participantId: number
  firstname: string | null
  lastname: string | null
  email: string | null
  username: string
  description: string | null
  additionalDescription: string | null
  resendInvitationMail: boolean
}

export type LoyaltyEditUserErrors = {
  participantId: FormError
  firstname: FormError
  lastname: FormError
  email: FormError
  username: FormError
  description: FormError
  additionalDescription: FormError
  resendInvitationMail: FormError
}

export type LoyaltyEditUserResponse = {
  data: {
    success: boolean
  }
}

export type LoyaltyUserDeactivateResponse = {
  data: {
    success: boolean
  }
}

export type LoyaltyUserVisibilityResponse = {
  data: {
    success: boolean
  }
}

import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { downloadXLSX, thousandsSeparator } from '../../../../../helpers/utils'
import { ImportLoyaltyAwardConfirmationsResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getLoyaltyAwardConfirmationsResponse = (
  importResponse: ImportLoyaltyAwardConfirmationsResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (importResponse.data.skippedParticipants) {
    const skippedParticipants = importResponse.data.skippedParticipants
    const addedResultsSummary = importResponse.data.addedResultsSummary
    const skippedResultsSummary = importResponse.data.skippedResultsSummary
    const updatedResultsSummary = importResponse.data.updatedResultsSummary

    const renderAdditionalInfo = () => {
      return (
        <>
          {skippedParticipants.length > 0 && (
            <div>
              <SecondaryButton
                onClick={() =>
                  downloadXLSX(
                    skippedParticipants,
                    t(
                      'import.messages.loyaltyProtocols.skippedResultsFilename',
                    ),
                  )
                }
                size="small"
                style={{ marginBottom: '10px' }}
              >
                {t('import.messages.loyaltyProtocols.downloadSkippedResults')}
              </SecondaryButton>
            </div>
          )}
        </>
      )
    }

    return {
      message: {
        type: alertType,
        message: t(`import.messages.loyaltyProtocols.${messageType}Message`, {
          addedResultsSummary: thousandsSeparator(addedResultsSummary || 0),
          skippedResultsSummary: thousandsSeparator(skippedResultsSummary || 0),
          updatedResultsSummary: thousandsSeparator(updatedResultsSummary || 0),
        }),
        hasSecondStep: false,
      },
      additionalInfo: renderAdditionalInfo(),
    }
  }
  return null
}

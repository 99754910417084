import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { downloadXLSX } from '../../../../../helpers/utils'
import { ImportChallengeUnitResultResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getChallengeSpResultsResponse = (
  importResponse: ImportChallengeUnitResultResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (
    (importResponse.data.addedResults ||
      importResponse.data.addedResults === 0) &&
    (importResponse.data.skippedResults ||
      importResponse.data.skippedResults === 0) &&
    importResponse.data.skippedStores &&
    importResponse.data.skippedProducts
  ) {
    const addedResults = importResponse.data.addedResults
    const skippedResults = importResponse.data.skippedResults
    const skippedStores = importResponse.data.skippedStores
    const skippedProducts = importResponse.data.skippedProducts

    const renderAdditionalInfo = () => {
      return (
        <>
          <div>
            {skippedStores.length > 0 && (
              <SecondaryButton
                onClick={() =>
                  downloadXLSX(
                    skippedStores.map((result) => ({
                      storeCentralId: result,
                    })),
                    t(
                      'import.messages.challengeUnitResults.skippedStoresFilename',
                    ),
                  )
                }
                size="small"
                style={{ marginBottom: '10px', marginRight: '5px' }}
              >
                {t(
                  'import.messages.challengeUnitResults.downloadSkippedStores',
                )}
              </SecondaryButton>
            )}
            {skippedProducts.length > 0 && (
              <SecondaryButton
                onClick={() =>
                  downloadXLSX(
                    skippedProducts.map((result) => ({
                      productCode: result,
                    })),
                    t(
                      'import.messages.challengeUnitResults.skippedProductsFilename',
                    ),
                  )
                }
                size="small"
                style={{ marginBottom: '10px' }}
              >
                {t(
                  'import.messages.challengeUnitResults.downloadSkippedProducts',
                )}
              </SecondaryButton>
            )}
          </div>
        </>
      )
    }

    return {
      message: {
        type: alertType,
        message: t(
          `import.messages.challengeUnitResults.${messageType}Message`,
          {
            addedResults: addedResults,
            skippedResults: skippedResults,
          },
        ),
        hasSecondStep: false,
      },
      additionalInfo: renderAdditionalInfo(),
    }
  }
  return null
}

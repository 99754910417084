import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../styles/Buttons/SecondaryButton'

type InfoDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  title: string | null
  content: string | null
}

const InfoDialog: React.FunctionComponent<InfoDialogProps> = ({
  open,
  handleClose,
  title,
  content,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogContent>
        <DialogContentText color="#000" textAlign="center">
          {title && <h4>{title}</h4>}
          {content && (
            <div
              style={{ fontSize: '14px', lineHeight: '24px' }}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          border: '1px solid #DCDCDC',
        }}
      >
        <Stack flexDirection="row" justifyContent="center" width="100%">
          <SecondaryButton onClick={() => handleClose(false)}>
            {t('common.ok')}
          </SecondaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default InfoDialog

import axios from 'axios'
import { LoyaltyPeriodListResponse } from '../store/LoyaltyPeriod/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPeriodList = async (): Promise<LoyaltyPeriodListResponse> => {
  return await axios.get(API_URL + '/api/admin/loyalty/period', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getPeriodList,
}

export default exportedObject

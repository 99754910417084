import axios from 'axios'
import { LoyaltyProtocolsResponse } from '../store/LoyaltyProtocols/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getLoyaltyProtocols = async (
  companies: { id: number }[],
  users: { id: number }[],
  periods: { id: number }[],
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  isConfirmed: boolean | null,
  isDownloaded: boolean | null,
): Promise<LoyaltyProtocolsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/protocol/list',
    {
      companies,
      users,
      periods,
      search,
      sortBy,
      sortOrder,
      per,
      page,
      isConfirmed,
      isDownloaded,
    },
    {
      headers: authHeader(),
    },
  )
}

const getProtocolPdfFile = async (protocolId: number): Promise<any> => {
  return await axios.get(
    API_URL + `/api/admin/loyalty/protocol/download/file/${protocolId}`,

    {
      responseType: 'arraybuffer', // important
      headers: authHeader(),
    },
  )
}

const getProtocolPdfsZip = async (
  protocols: { id: number }[],
): Promise<any> => {
  return await axios.post(
    API_URL + `/api/admin/loyalty/protocol/download/archive`,
    {
      protocols,
    },
    {
      responseType: 'arraybuffer', // important
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getLoyaltyProtocols,
  getProtocolPdfFile,
  getProtocolPdfsZip,
}

export default exportedObject

import axios from 'axios'
import { ImageContentResponse } from '../store/Image/types'
import {
  StoresImportData,
  StoresResponse,
  StoresImportResponse,
  StoreNameResponse,
} from '../store/Store/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getStores = async (
  companies: { id: number }[],
  users: { id: number }[],
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  isVisible: boolean | null,
  isActive: boolean | null,
): Promise<StoresResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/list',
    {
      companies,
      users,
      search,
      sortBy,
      sortOrder,
      per,
      page,
      isVisible,
      isActive,
    },
    {
      headers: authHeader(),
    },
  )
}

const importStores = async (
  data: StoresImportData[],
): Promise<StoresImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/import',
    {
      data,
    },
    {
      headers: authHeader(),
    },
  )
}

const getImageContent = (imageId: number): Promise<ImageContentResponse> => {
  return axios.get(API_URL + `/api/admin/image/store/${imageId}`, {
    headers: authHeader(),
  })
}

const getStoreName = async (
  storeCentralId: string,
): Promise<StoreNameResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/name/check',
    {
      storeCentralId,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getStores,
  importStores,
  getImageContent,
  getStoreName,
}

export default exportedObject

import axios from 'axios'
import {
  CalculateRealizationResponse,
  OrdersResponse,
  OrderWeekResponse,
  OrderYearResponse,
} from '../store/Order/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getOrders = async (
  companies: { id: number }[],
  users: { id: number }[],
  weeks: number[],
  years: number[],
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<OrdersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/order/list',
    {
      companies,
      users,
      weeks,
      years,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getOrderWeek = async (): Promise<OrderWeekResponse> => {
  return await axios.get(API_URL + '/api/admin/store/order/week', {
    headers: authHeader(),
  })
}

const getOrderYear = async (): Promise<OrderYearResponse> => {
  return await axios.get(API_URL + '/api/admin/store/order/year', {
    headers: authHeader(),
  })
}

const calculateRealization =
  async (): Promise<CalculateRealizationResponse> => {
    return await axios.post(
      API_URL + '/api/admin/store/order/refresh-view',
      {},
      {
        headers: authHeader(),
      },
    )
  }

const exportedObject = {
  getOrders,
  getOrderWeek,
  getOrderYear,
  calculateRealization,
}

export default exportedObject

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import OrdersToolbar from '../partials/OrdersToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import UserService from '../../../../services/user.service'
import OrderService from '../../../../services/order.service'
import CompanyService from '../../../../services/company.service'
import { getHiddenColumns, thousandsSeparator } from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { Order } from '../../../../store/Order/types'
import { UserFilter } from '../../../../store/User/types'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import ImportDialog from '../../../shared/ImportDialog'
import { toast } from 'react-toastify'
import InfoDialog from '../../../shared/InfoDialog'

type OrdersListProps = {
  path: string
  user: User
}

const OrdersList: React.FC<OrdersListProps> = ({ path, user }) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'orders'

  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>('')

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filteredOrdersList, setFilteredOrdersList] = useState<Order[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [users, setUsers] = useState<UserFilter[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Option[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])
  const [weeks, setWeeks] = useState<Option[]>([])
  const [selectedWeeks, setSelectedWeeks] = useState<Option[]>([])
  const [years, setYears] = useState<Option[]>([])
  const [selectedYears, setSelectedYears] = useState<Option[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [openImportDialog, setImportDialogOpen] = useState(false)
  const [openCalculateRealizationDialog, setOpenCalculateRealizationDialog] =
    useState<boolean>(false)
  const [refresh, setRefresh] = useState(false)

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
    setRefresh((prevState) => !prevState)
  }

  const handleImportDialogClickOpen = () => {
    setImportDialogOpen(true)
  }

  const handleCalculateRealizationDialogClose = () => {
    setOpenCalculateRealizationDialog(false)
  }

  const handleCalculateRealization = async () => {
    try {
      const calculateRealizationResponse =
        await OrderService.calculateRealization()
      if (calculateRealizationResponse.data.success) {
        toast.success(t('messages.success.calculateRealization'))
        setRefresh((prevState) => !prevState)
        setOpenCalculateRealizationDialog(true)
      }
    } catch (error) {
      errorHandler(error, t)
    }
  }

  const generateTableColumns = useCallback(
    (orders: Order[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.orders.table.storeId').toString(),
          accessor: 'storeCentralId',
          width: 110,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.orders.table.storeName').toString(),
          accessor: 'storeName',
          width: 300,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.orders.table.city').toString(),
          accessor: 'city',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.orders.table.companyName').toString(),
          accessor: 'companyName',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userCentralId',
          Header: t('pages.orders.table.userCentralId').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'firstname',
          Header: t('pages.orders.table.firstname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'lastname',
          Header: t('pages.orders.table.lastname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'year',
          Header: t('pages.orders.table.year').toString(),
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'week',
          Header: t('pages.orders.table.week').toString(),
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'totalDeliveryValue',
          Header: t('pages.orders.table.totalDeliveryValue').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        },
        {
          accessor: 'weekRealizationPercent',
          Header: t('pages.orders.table.weekRealizationPercent').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : ''}
            </Stack>
          ),
        },
        {
          accessor: 'cat1DeliveryValue',
          Header: t('pages.orders.table.cat1DeliveryValue').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        },
        {
          accessor: 'cat1RealizationPercent',
          Header: t('pages.orders.table.cat1RealizationPercent').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : ''}
            </Stack>
          ),
        },
        {
          accessor: 'cat2DeliveryValue',
          Header: t('pages.orders.table.cat2DeliveryValue').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        },
        {
          accessor: 'cat2RealizationPercent',
          Header: t('pages.orders.table.cat2RealizationPercent').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : ''}
            </Stack>
          ),
        },
        {
          accessor: 'cat3DeliveryValue',
          Header: t('pages.orders.table.cat3DeliveryValue').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        },
        {
          accessor: 'cat3RealizationPercent',
          Header: t('pages.orders.table.cat3RealizationPercent').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : ''}
            </Stack>
          ),
        },
        {
          accessor: 'actions',
          Header: t('pages.orders.table.actions').toString(),
          width: 60,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <Tooltip title={`${t('pages.orders.table.preview')}`}>
              <IconButton
                onClick={() => console.log(params.row.original.storeId)}
                size="small"
                style={{ padding: 0, marginRight: '5px' }}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          ),
        },
      )

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const response = await OrderService.getOrders(
            selectedCompanies.map((company) => ({ id: company.value })),
            selectedUsers.map((user) => ({ id: user.value })),
            selectedWeeks.map((week) => week.value),
            selectedYears.map((year) => year.value),
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (response.data.storeOrders) {
            setTableColumns(generateTableColumns(response.data.storeOrders))

            setFilteredOrdersList(response.data.storeOrders)

            setTotalCount(response.data.totalCount)
            setPageCount(Math.ceil(response.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [
      t,
      searchValue,
      generateTableColumns,
      selectedUsers,
      selectedCompanies,
      selectedWeeks,
      selectedYears,
    ],
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)

        const weeksReposne = await OrderService.getOrderWeek()

        if (weeksReposne.data.weeks) {
          const multiSelectOptions: Option[] = []
          weeksReposne.data.weeks.forEach((week) =>
            multiSelectOptions.push({
              value: week.week,
              label: `${t('pages.orders.toolbar.filters.week')} ${week.week}`,
            }),
          )
          setWeeks(multiSelectOptions)
          setSelectedWeeks(multiSelectOptions)
        }

        const yearsResponse = await OrderService.getOrderYear()

        if (yearsResponse.data.years) {
          const multiSelectOptions: Option[] = []
          yearsResponse.data.years.forEach((year) =>
            multiSelectOptions.push({
              value: year,
              label: `${t('pages.orders.toolbar.filters.year')} ${year}`,
            }),
          )
          setYears(multiSelectOptions)
          setSelectedYears(multiSelectOptions)
        }

        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setSelectedCompanies(multiSelectOptions)
        }

        const userListResponse = await UserService.getUserListFilter()

        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )
          setUsers(userListResponse.data.users)
          setSelectedUsers(multiSelectOptions)
          setFilteredUsers(multiSelectOptions)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [refresh, t])

  useEffect(() => {
    const filterUsers = () => {
      const filteredUsersForMultiselect = users
        .filter((user) =>
          selectedCompanies
            .map((company) => company.value)
            .includes(user.companyId),
        )
        .map((u) => ({
          value: u.id,
          label: `${u.lastname} ${u.firstname}`,
        }))
      setFilteredUsers(filteredUsersForMultiselect)
      setSelectedUsers(filteredUsersForMultiselect)
    }
    filterUsers()
  }, [selectedCompanies, users])

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const response = await OrderService.getOrders(
        selectedCompanies.map((company) => ({ id: company.value })),
        selectedUsers.map((user) => ({ id: user.value })),
        selectedWeeks.map((week) => week.value),
        selectedYears.map((year) => year.value),
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataOrders = response.data.storeOrders
      if (dataOrders) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'storeCentralId',
            'storeName',
            'city',
            'companyName',
            'userCentralId',
            'firstname',
            'lastname',
            'regionName',
            'week',
            'year',
            'totalDeliveryValue',
            'weekRealizationPercent',
            'cat1DeliveryValue',
            'cat1RealizationPercent',
            'cat2DeliveryValue',
            'cat2RealizationPercent',
            'cat3DeliveryValue',
            'cat3RealizationPercent',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredOrdersList = dataOrders.map((order) =>
          pick(order, visibleColumns),
        )

        const translatedHeaders = {
          storeCentralId: t('excel.orders.storeId'),
          storeName: t('excel.orders.storeName'),
          city: t('excel.orders.city'),
          companyName: t('excel.orders.companyName'),
          userCentralId: t('excel.orders.userCentralId'),
          firstname: t('excel.orders.firstname'),
          lastname: t('excel.orders.lastname'),
          regionName: t('excel.orders.regionName'),
          week: t('excel.orders.week'),
          year: t('excel.orders.year'),
          totalDeliveryValue: t('excel.orders.totalDeliveryValue'),
          weekRealizationPercent: t('excel.orders.weekRealizationPercent'),
          cat1DeliveryValue: t('excel.orders.cat1DeliveryValue'),
          cat1RealizationPercent: t('excel.orders.cat1RealizationPercent'),
          cat2DeliveryValue: t('excel.orders.cat2DeliveryValue'),
          cat2RealizationPercent: t('excel.orders.cat2RealizationPercent'),
          cat3DeliveryValue: t('excel.orders.cat3DeliveryValue'),
          cat3RealizationPercent: t('excel.orders.cat3RealizationPercent'),
        }

        const headers = [
          Object.keys(filteredOrdersList[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredOrdersList, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            {' '}
            <Stack display="flex" alignContent="flex-start" flexDirection="row">
              <PrimaryButton
                variant="contained"
                onClick={handleImportDialogClickOpen}
              >
                {t('pages.orders.import')}
              </PrimaryButton>
              <PrimaryButton
                variant="contained"
                onClick={handleCalculateRealization}
                sx={{ marginLeft: 2 }}
              >
                {t('pages.orders.calculateRealization')}
              </PrimaryButton>
            </Stack>
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('orders')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>

          <OrdersToolbar
            value={searchText}
            companies={companies}
            selectedCompanies={selectedCompanies}
            users={filteredUsers}
            selectedUsers={selectedUsers}
            weeks={weeks}
            selectedWeeks={selectedWeeks}
            years={years}
            selectedYears={selectedYears}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
            }}
            setSelectedCompanies={(companies: Option[]) => {
              setSelectedCompanies(companies)
              setSkipPageReset(false)
            }}
            setSelectedUsers={(users: Option[]) => {
              setSelectedUsers(users)
              setSkipPageReset(false)
            }}
            setSelectedWeeks={(weeks: Option[]) => {
              setSelectedWeeks(weeks)
              setSkipPageReset(false)
            }}
            setSelectedYears={(years: Option[]) => {
              setSelectedYears(years)
              setSkipPageReset(false)
            }}
            user={user}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredOrdersList}
            height="calc(100vh - 330px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={0}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'companyName',
              'firstname',
              'lastname',
              'cat1DeliveryValue',
              'cat1RealizationPercent',
              'cat2DeliveryValue',
              'cat2RealizationPercent',
              'cat3DeliveryValue',
              'cat3RealizationPercent',
            ]}
            toggleVisibility={setColumnsVisibility}
          />
          <ImportDialog
            open={openImportDialog}
            handleClose={handleImportDialogClose}
            name={'store_orders'}
            title={t('pages.orders.import')}
          />
          <InfoDialog
            open={openCalculateRealizationDialog}
            handleClose={handleCalculateRealizationDialogClose}
            title={t('pages.orders.calculateRealizationDialog.title')}
            content={t('pages.orders.calculateRealizationDialog.info')}
          />
        </>
      )}
    </>
  )
}

export default OrdersList

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, SelectChangeEvent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChallengesStoresToolbar from '../partials/ChallengesStoresToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column, ColumnInstance } from 'react-table'
import {
  ChallengeFilter,
  ChallengeStore,
} from '../../../../store/Challenge/types'
import TableControlled from '../../../Table/TableControlled'
import PeriodService from '../../../../services/period.service'
import ChallengeService from '../../../../services/challenge.service'
import CompanyService from '../../../../services/company.service'
import UserService from '../../../../services/user.service'
import { getHiddenColumns, thousandsSeparator } from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { UserFilter } from '../../../../store/User/types'
import { getTableState, setTableState } from '../../../../helpers/utils'

type ChallengesStoresListProps = {
  path: string
  user: User
}

const ChallengesStoresList: React.FC<ChallengesStoresListProps> = ({
  path,
  user,
}) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'challengesStores'

  const searchState = getTableState(tableName, 'search')
  const periodState = getTableState(tableName, 'period')
  const [controlledPageIndex, setControlledPageIndex] = useState(0)

  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [periodValue, setPeriodValue] = useState<string>(
    periodState ? periodState : 'all',
  )
  const [challengeValue, setChallengeValue] = useState<string>('')

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filteredChallengeStoreList, setFilteredChallengeStoreList] = useState<
    ChallengeStore[]
  >([])
  const [periods, setPeriods] = useState<Period[]>([])
  const [challenges, setChallenges] = useState<ChallengeFilter[]>([])
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const [users, setUsers] = useState<UserFilter[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Option[]>([])
  const [filteredChallenges, setFilteredChallenges] = useState<
    ChallengeFilter[]
  >([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])

  const generateTableColumns = useCallback(
    (challengesStores: ChallengeStore[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.challengesStores.table.challengeId').toString(),
          accessor: 'challengeId',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.challengesStores.table.challengeName').toString(),
          accessor: 'challengeName',
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userCentralId',
          Header: t('pages.challengesStores.table.userCentralId').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userFirstname',
          Header: t('pages.challengesStores.table.userFirstname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userLastname',
          Header: t('pages.challengesStores.table.userLastname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.challengesStores.table.companyName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'regionName',
          Header: t('pages.challengesStores.table.regionName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.challengesStores.table.storeId').toString(),
          accessor: 'storeId',
          width: 140,
        },
        {
          Header: t('pages.challengesStores.table.storeCentralId').toString(),
          accessor: 'storeCentralId',
          width: 140,
        },
        {
          Header: t('pages.challengesStores.table.storeInternalId').toString(),
          accessor: 'storeInternalId',
          width: 140,
        },
        {
          Header: t('pages.challengesStores.table.storeName').toString(),
          accessor: 'storeName',
          width: 300,
        },
        {
          Header: t('pages.challengesStores.table.storeCity').toString(),
          accessor: 'storeCity',
          width: 180,
        },
        {
          Header: t('pages.challengesStores.table.storeStreet').toString(),
          accessor: 'storeStreet',
          width: 160,
        },
        {
          accessor: 'minimalTotalPercent',
          Header: t(
            'pages.challengesStores.table.minimalTotalPercent',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value) + '%'
                : t('pages.challengesStores.table.null')}
            </Stack>
          ),
        },
        {
          Header: t(
            'pages.challengesStores.table.requirementsCompleted',
          ).toString(),
          accessor: 'requirementsCompleted',
          width: 100,
          Cell: (params: any) => (
            <>{params.value ? t('common.yes') : t('common.no')}</>
          ),
        },
      )

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const response = await ChallengeService.getChallengeStores(
            [{ id: parseInt(challengeValue) }],
            selectedCompanies.map((company) => ({ id: company.value })),
            selectedUsers.map((user) => ({ id: user.value })),
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (response.data.challengeStores) {
            setTableColumns(generateTableColumns(response.data.challengeStores))

            setFilteredChallengeStoreList(response.data.challengeStores)

            setTotalCount(response.data.totalCount)
            setPageCount(Math.ceil(response.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [t, searchValue, generateTableColumns, challengeValue, selectedUsers], // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)
        const periodListResponse = await PeriodService.getPeriodList()
        let activePeriod = null

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          activePeriod = periodListResponse.data.periods.find(
            (period) => period.isActive,
          )
          if (periodState) {
            setPeriodValue(periodState)
          } else {
            setPeriodValue(activePeriod ? activePeriod.id.toString() : '')
          }
        }

        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setSelectedCompanies(multiSelectOptions)
        }

        const challengesFilterResponse =
          await ChallengeService.getChallengeFilter()

        if (challengesFilterResponse.data.challenges) {
          setChallenges(challengesFilterResponse.data.challenges)
        }

        const userListResponse = await UserService.getUserListFilter()

        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )
          setUsers(userListResponse.data.users)
          setSelectedUsers(multiSelectOptions)
          setFilteredUsers(multiSelectOptions)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t, periodState])

  useEffect(() => {
    const filterChallenges = () => {
      const filteredChallengesForSelect = challenges.filter(
        (challenge) => challenge.periodId === parseInt(periodValue),
      )
      setFilteredChallenges(filteredChallengesForSelect)
      setChallengeValue(filteredChallengesForSelect[0].id.toString())
    }
    if (periodValue !== '' && challenges.length > 0) {
      filterChallenges()
    }
  }, [periodValue, challenges])

  useEffect(() => {
    const filterUsers = () => {
      const filteredUsersForMultiselect = users
        .filter((user) =>
          selectedCompanies
            .map((company) => company.value)
            .includes(user.companyId),
        )
        .map((u) => ({
          value: u.id,
          label: `${u.lastname} ${u.firstname}`,
        }))
      setFilteredUsers(filteredUsersForMultiselect)
      setSelectedUsers(filteredUsersForMultiselect)
    }
    filterUsers()
  }, [selectedCompanies, users])

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const response = await ChallengeService.getChallengeStores(
        [{ id: parseInt(challengeValue) }],
        selectedCompanies.map((company) => ({ id: company.value })),
        selectedUsers.map((user) => ({ id: user.value })),
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataChallenges = response.data.challengeStores
      if (dataChallenges) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'challengeId',
            'challengeName',
            'userCentralId',
            'userFirstname',
            'userLastname',
            'companyName',
            'regionName',
            'storeId',
            'storeCentralId',
            'storeInternalId',
            'storeName',
            'storeCity',
            'storeStreet',
            'minimalTotalPercent',
            'requirementsCompleted',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredChallengeStoreList = dataChallenges.map((challenge) => {
          challenge.requirementsCompleted = challenge.requirementsCompleted
            ? t('common.yes').toString()
            : t('common.no').toString()
          return pick(challenge, visibleColumns)
        })

        const translatedHeaders = {
          challengeId: t('excel.challengesStores.challengeId'),
          challengeName: t('excel.challengesStores.challengeName'),
          userCentralId: t('excel.challengesStores.userCentralId'),
          userFirstname: t('excel.challengesStores.userFirstname'),
          userLastname: t('excel.challengesStores.userLastname'),
          companyName: t('excel.challengesStores.companyName'),
          regionName: t('excel.challengesStores.regionName'),
          storeId: t('excel.challengesStores.storeId'),
          storeCentralId: t('excel.challengesStores.storeCentralId'),
          storeInternalId: t('excel.challengesStores.storeInternalId'),
          storeName: t('excel.challengesStores.storeName'),
          storeCity: t('excel.challengesStores.storeCity'),
          storeStreet: t('excel.challengesStores.storeStreet'),
          minimalTotalPercent: t('excel.challengesStores.minimalTotalPercent'),
          requirementsCompleted: t(
            'excel.challengesStores.requirementsCompleted',
          ),
        }

        const headers = [
          Object.keys(filteredChallengeStoreList[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredChallengeStoreList, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('challengesStores')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>

          <ChallengesStoresToolbar
            periods={periods}
            periodValue={periodValue}
            value={searchText}
            companies={companies}
            selectedCompanies={selectedCompanies}
            users={filteredUsers}
            challenges={filteredChallenges}
            challengeValue={challengeValue}
            selectedUsers={selectedUsers}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
              setControlledPageIndex(0)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            filterChallenge={(event: SelectChangeEvent) => {
              setChallengeValue(event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            setSelectedCompanies={(companies: Option[]) => {
              setSelectedCompanies(companies)
              setSkipPageReset(false)
              setControlledPageIndex(0)
            }}
            setSelectedUsers={(users: Option[]) => {
              setSelectedUsers(users)
              setSkipPageReset(false)
              setControlledPageIndex(0)
            }}
            user={user}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredChallengeStoreList}
            height="calc(100vh - 330px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'userFirstname',
              'userLastname',
              'companyName',
              'regionName',
              'storeId',
              'storeInternalId',
            ]}
            toggleVisibility={setColumnsVisibility}
            saveTableState={false}
          />
        </>
      )}
    </>
  )
}

export default ChallengesStoresList

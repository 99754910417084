import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  FormHelperText,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import {
  Group,
  LoyaltyAddUserErrors,
  LoyaltyAddUserParams,
  LoyaltyUserTypeCode,
} from '../../../../store/LoyaltyUser/types'
import LoyaltyUserService from '../../../../services/loyaltyUser.service'
import StoreService from '../../../../services/store.service'
import { validateEmail } from '../../../../helpers/utils'
import { toast } from 'react-toastify'

type LoyaltyUserAddDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  type: LoyaltyUserTypeCode
}

const LoyaltyUserAddDialog: React.FunctionComponent<
  LoyaltyUserAddDialogProps
> = ({ open, handleClose, type }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)

  const [storeLoading, setStoreLoading] = useState<boolean>(false)

  const [groups, setGroups] = useState<Group[]>([])
  const [groupId, setGroupId] = useState<string>('')
  const [centralId, setCentralId] = useState<string>('')
  const [storeCentralId, setStoreCentralId] = useState<string>('')
  const [storeName, setStoreName] = useState<string>('')
  const [firstname, setFirstname] = useState<string | null>('')
  const [lastname, setLastname] = useState<string | null>('')
  const [email, setEmail] = useState<string | null>('')
  const [username, setUsername] = useState<string>('')
  const [description, setDescription] = useState<string | null>('')
  const [descriptionLength, setDescriptionLength] = useState<number>(0)
  const [additionalDescriptionLength, setAdditionalDescriptionLength] =
    useState<number>(0)
  const [additionalDescription, setAdditionalDescription] = useState<
    string | null
  >('')
  const [password, setPassword] = useState<any>('')
  const [generatePassword, setGeneratePassword] = useState<boolean>(false)

  const [storeExists, setStoreExists] = useState<boolean>(false)
  const [storeExistsMessage, setStoreExistsMessage] = useState<boolean>(false)

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: LoyaltyAddUserErrors = {
    groupId: defaultError,
    centralId: defaultError,
    storeCentralId: defaultError,
    firstname: defaultError,
    lastname: defaultError,
    email: defaultError,
    username: defaultError,
    description: defaultError,
    additionalDescription: defaultError,
    password: defaultError,
    generatePassword: defaultError,
    chooseWayToGeneratePassword: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<LoyaltyAddUserErrors>(defaultFormErrors)

  const handleGroupChange = (event: SelectChangeEvent) => {
    setGroupId(event.target.value as string)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleFirstnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFirstname(event.target.value)
  }

  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= 100) {
      setDescription(event.target.value)
      setDescriptionLength(event.target.value.length)
    }
  }
  const handleAdditionalDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= 255) {
      setAdditionalDescription(event.target.value)
      setAdditionalDescriptionLength(event.target.value.length)
    }
  }

  const handleGeneratePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setGeneratePassword(event.target.checked)
    if (event.target.checked) setPassword('')
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    if (event.target.value.length || event.target.value.length > 0)
      setGeneratePassword(false)
  }

  const handleCentralIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCentralId(event.target.value)
  }
  //check if storeName exists in Database
  const handleStoreCentralIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStoreCentralId(event.target.value)
    if (event.target.value.length > 0) {
      setStoreLoading(true)
      try {
        const storeNameResponse = await StoreService.getStoreName(
          event.target.value,
        )

        if (storeNameResponse.data.name) {
          setStoreName(storeNameResponse.data.name)
          setStoreExists(true)
        }
        if (!storeNameResponse.data.name) {
          setStoreName('')
          setStoreExists(false)
          setStoreExistsMessage(true)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setStoreLoading(false)
      }
    }
    if (event.target.value.length === 0) {
      setStoreName('')
      setStoreExists(false)
      setStoreExistsMessage(false)
    }
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setGroupId('')
    setGroups([])
    setGroupId('')
    setCentralId('')
    setStoreCentralId('')
    setStoreName('')
    setFirstname('')
    setLastname('')
    setEmail('')
    setUsername('')
    setDescription('')
    setAdditionalDescription('')
    setPassword('')
    setGeneratePassword(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // get groups
        const groupsResponse = await LoyaltyUserService.getGroupList()
        if (groupsResponse.data.groups) {
          setGroups(groupsResponse.data.groups)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [open, t])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const userErrors = defaultFormErrors

    if (groupId === '') {
      setFormErrors({
        ...userErrors,
        groupId: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.required',
          ),
        },
      })
      return
    } else if (centralId.trim() === '') {
      setFormErrors({
        ...userErrors,
        centralId: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.required',
          ),
        },
      })
      return
    } else if (storeCentralId.trim() === '') {
      setFormErrors({
        ...userErrors,
        storeCentralId: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.required',
          ),
        },
      })
      return
    } else if (!storeExists) {
      return
    } else if (email && !validateEmail(email)) {
      setFormErrors({
        ...userErrors,
        email: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.emailInvalid',
          ),
        },
      })
      return
    } else if (username.trim() === '') {
      setFormErrors({
        ...userErrors,
        username: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.required',
          ),
        },
      })
      return
    } else if (!generatePassword && password.trim() === '') {
      setFormErrors({
        ...userErrors,
        password: {
          error: true,
          message: '',
        },
        chooseWayToGeneratePassword: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.chooseWayToGeneratePassword',
          ),
        },
      })
      return
    } else if (!email && generatePassword) {
      setFormErrors({
        ...userErrors,
        email: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.emailForPassword',
          ),
        },
      })

      return
    }

    const formParams: LoyaltyAddUserParams = {
      groupId: parseInt(groupId),
      centralId,
      storeCentralId,
      firstname: firstname?.trim() === '' ? null : firstname,
      lastname: lastname?.trim() === '' ? null : lastname,
      email: email?.trim() === '' ? null : email,
      username,
      description: description?.trim() === '' ? null : description,
      additionalDescription:
        additionalDescription?.trim() === '' ? null : additionalDescription,
      participantTypeCode: type,
      password: password.trim() === '' ? null : password,
      generatePassword,
    }

    try {
      setSaving(true)
      const addUserResponse = await LoyaltyUserService.addLoyaltyUser(
        formParams,
      )

      if (addUserResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.title')}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={5}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={formErrors.groupId.error}
                >
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.groups')} *
                  </label>
                  <Select
                    id="company-select"
                    value={groupId}
                    onChange={handleGroupChange}
                    size="small"
                  >
                    {groups.map((group) => (
                      <MenuItem value={group.id} key={`user-add-${group.id}`}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.groupId.error && (
                    <FormHelperText>
                      {formErrors.groupId.error && formErrors.groupId.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.centralId')}{' '}
                    *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={centralId}
                    onChange={handleCentralIdChange}
                    error={formErrors.centralId.error}
                    helperText={
                      formErrors.centralId.error && formErrors.centralId.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyAddUserDialog.storeCentralId',
                    )}{' '}
                    *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={storeCentralId}
                    onChange={handleStoreCentralIdChange}
                    error={formErrors.storeCentralId.error}
                    helperText={
                      formErrors.storeCentralId.error &&
                      formErrors.storeCentralId.message
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.storeName')}{' '}
                    *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={storeName}
                    disabled
                    placeholder={t(
                      'pages.loyaltyUsersprt.loyaltyAddUserDialog.storeNamePlaceholder',
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '4px',
                  height: 10,
                }}
              >
                {storeLoading && storeExistsMessage && (
                  <>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyAddUserDialog.storeSearching',
                    )}
                  </>
                )}
                {!storeLoading && storeExists && storeExistsMessage && (
                  <div style={{ color: 'green' }}>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.storeFound')}
                  </div>
                )}
                {!storeLoading && !storeExists && storeExistsMessage && (
                  <div style={{ color: '#d32f2f' }}>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyAddUserDialog.storeDoNotExist',
                    )}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.firstname')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={formErrors.firstname.error}
                    helperText={
                      formErrors.firstname.error && formErrors.firstname.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.lastname')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={lastname}
                    onChange={handleLastnameChange}
                    error={formErrors.lastname.error}
                    helperText={
                      formErrors.lastname.error && formErrors.lastname.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.email')} *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={email}
                    onChange={handleEmailChange}
                    error={formErrors.email.error}
                    helperText={
                      formErrors.email.error && formErrors.email.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.username')} *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={username}
                    onChange={handleUsernameChange}
                    error={formErrors.username.error}
                    helperText={
                      formErrors.username.error && formErrors.username.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyAddUserDialog.description',
                    )}
                  </label>
                  <TextField
                    multiline
                    rows={2}
                    value={description}
                    onChange={handleDescriptionChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <div className="character-amount">{descriptionLength}/100</div>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyAddUserDialog.additionalDescription',
                    )}
                  </label>
                  <TextField
                    multiline
                    rows={4}
                    value={additionalDescription}
                    onChange={handleAdditionalDescriptionChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <div className="character-amount">
                  {additionalDescriptionLength}/255
                </div>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={generatePassword}
                      onChange={handleGeneratePasswordChange}
                      disabled={password}
                    />
                  }
                  label={t(
                    'pages.loyaltyUsersprt.loyaltyAddUserDialog.generatePassword',
                  )}
                />
              </Grid>
              <Grid
                item
                md={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                }}
              >
                {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.or')}
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyAddUserDialog.password')} *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={password}
                    onChange={handlePasswordChange}
                    error={formErrors.password.error}
                    disabled={generatePassword}
                    helperText={
                      formErrors.password.error && formErrors.password.message
                    }
                  />
                </FormControl>
              </Grid>
              {formErrors.chooseWayToGeneratePassword.error && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    color: '#d32f2f',
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    lineHeight: '1.66',
                    letterSpacing: '0.03333em',
                    textAlign: 'center',
                    marginTop: '4px',
                  }}
                >
                  {formErrors.chooseWayToGeneratePassword.message}
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default LoyaltyUserAddDialog

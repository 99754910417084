import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import CompanyService from '../../../../services/company.service'
import StoreService from '../../../../services/store.service'
import PeriodService from '../../../../services/period.service'
import UserService from '../../../../services/user.service'
import { useTranslation } from 'react-i18next'
import StoresToolbar from '../partials/StoresToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Store } from '../../../../store/Store/types'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { User } from '../../../../store/Auth/types'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
} from '../../../../helpers/utils'
import { isCentralAdmin, isSuperAdmin } from '../../../../helpers/checkRole'
import { Option } from '../../../../store/types'
import ImportDialog from '../../../shared/ImportDialog'
import { Period } from '../../../../store/Period/types'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import StoreImageDialog from '../partials/StoreImageDialog'

type StoresListProps = {
  user: User
}

const StoresList: FunctionComponent<StoresListProps> = ({ user }) => {
  const tableName = 'stores'
  const searchState = getTableState(tableName, 'search')
  const statusState = getTableState(tableName, 'status')
  const visibilityState = getTableState(tableName, 'visibility')

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredStoresList, setFilteredStoresList] = useState<Store[]>([])
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [statusValue, setStatusValue] = useState<string>(
    statusState ? statusState : 'all',
  )
  const [visibilityValue, setVisibilityValue] = useState<string>(
    visibilityState ? visibilityState : 'all',
  )
  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [users, setUsers] = useState<Option[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])

  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [openImportDialog, setImportDialogOpen] = useState(false)
  const [periodValue, setPeriodValue] = useState<string>('')
  const [periods, setPeriods] = useState<Period[]>([])

  const [importName, setImportName] = useState<string | null>(null)
  const [importTitle, setImportTitle] = useState<string>()
  const [importParam, setImportParam] = useState<number>()

  const [storeImageDialogOpen, setStoreImageDialogOpen] =
    useState<boolean>(false)
  const [storeImageId, setStoreImageId] = useState<number>()

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
  }

  const handleImportDialogClickOpen = (name: string, periodId?: string) => {
    switch (name) {
      case 'stores':
        setImportTitle(t('pages.stores.import.title'))
        break
      case 'store_period':
        setImportTitle(
          t('pages.stores.importStorePeriod.title', {
            periodName: periods.find((p) => p.id === parseInt(periodId!))?.name,
          }),
        )
        setImportParam(parseInt(periodId!))
        break

      default:
        break
    }

    setImportName(name)
    setImportDialogOpen(true)
  }

  const handleStoreImageDialogClickOpen = (imageId: number) => {
    setStoreImageId(imageId)
    setStoreImageDialogOpen(true)
  }

  const handleStoreImageDialogClose = () => {
    setStoreImageDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (stores: Store[]) => {
      const columns = []
      columns.push(
        // {
        //   accessor: 'edit',
        //   Header: '',
        //   width: 40,
        //   disableSortBy: true,
        //   Cell: (params: any) => (
        //     <IconButton
        //       onClick={() => console.log('edit')}
        //       size="small"
        //       style={{ padding: 0 }}
        //     >
        //       <EditIcon />
        //     </IconButton>
        //   ),
        // },
        {
          Header: t('pages.stores.table.companyName').toString(),
          accessor: 'companyName',
          width: 200,
        },
        {
          Header: t('pages.stores.table.userCentralId').toString(),
          accessor: 'userCentralId',
          width: 160,
        },
        {
          Header: t('pages.stores.table.userFirstname').toString(),
          accessor: 'userFirstname',
          width: 170,
        },
        {
          Header: t('pages.stores.table.userLastname').toString(),
          accessor: 'userLastname',
          width: 170,
        },
        {
          Header: t('pages.stores.table.centralId').toString(),
          accessor: 'centralId',
          width: 140,
        },
        {
          Header: t('pages.stores.table.storeInternalId').toString(),
          accessor: 'internalId',
          width: 140,
        },
        {
          Header: t('pages.stores.table.name').toString(),
          accessor: 'name',
          width: 300,
        },
        {
          Header: t('pages.stores.table.countryName').toString(),
          accessor: 'countryName',
          width: 140,
        },
        {
          Header: t('pages.stores.table.city').toString(),
          accessor: 'city',
          width: 180,
        },
        {
          Header: t('pages.stores.table.street').toString(),
          accessor: 'street',
          width: 160,
        },
        {
          Header: t('pages.stores.table.provinceName').toString(),
          accessor: 'provinceName',
          width: 170,
        },
        {
          Header: t('pages.stores.table.taxNumber').toString(),
          accessor: 'taxNumber',
          width: 130,
        },
        {
          Header: t('pages.stores.table.saleBranchName').toString(),
          accessor: 'saleBranchName',
          width: 120,
        },
        {
          Header: t('pages.stores.table.saleTypesName').toString(),
          accessor: 'saleTypesName',
          width: 150,
        },
        {
          Header: t('pages.stores.table.saleAreaName').toString(),
          accessor: 'saleAreaName',
          width: 200,
        },
        {
          Header: t('pages.stores.table.detail1').toString(),
          accessor: 'detail1',
          width: 150,
        },
        {
          Header: t('pages.stores.table.detail2').toString(),
          accessor: 'detail2',
          width: 150,
        },
        {
          Header: t('pages.stores.table.isActive').toString(),
          accessor: 'isActive',
          width: 110,
          Cell: (params: any) => (
            <>
              {!params.value && <>{t('pages.stores.statuses.inactive')}</>}
              {params.value && <>{t('pages.stores.statuses.active')}</>}
            </>
          ),
        },
        {
          Header: t('pages.stores.table.isVisible').toString(),
          accessor: 'isVisible',
          width: 110,
          Cell: (params: any) => (
            <>
              {!params.value && <>{t('pages.stores.statuses.invisible')}</>}
              {params.value && <>{t('pages.stores.statuses.visible')}</>}
            </>
          ),
        },
        {
          Header: t('pages.stores.table.storeCategoryName').toString(),
          accessor: 'storeCategoryName',
          width: 150,
        },
        {
          Header: t('pages.stores.table.storeGroupName').toString(),
          accessor: 'storeGroupName',
          width: 150,
        },
        {
          Header: t('pages.stores.table.modified').toString(),
          accessor: 'modified',
          width: 150,
        },
        {
          accessor: 'actions',
          Header: t('pages.stores.table.actions').toString(),
          disableSortBy: true,
          sticky: 'right',
          width: 55,
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={t('pages.stores.table.imagePreview')}>
                  <IconButton
                    aria-label="show"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: '5px',
                    }}
                    onClick={() =>
                      handleStoreImageDialogClickOpen(
                        params.row.original.storeId,
                      )
                    }
                  >
                    <ImageSearchIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )

      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchCompaniesData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          const companiesState = getTableState(tableName, 'companies')
          if (companiesState) {
            setSelectedCompanies(JSON.parse(companiesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }

    const fetchUsersData = async () => {
      try {
        const userListResponse = await UserService.getUserListFilter()
        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )
          setUsers(multiSelectOptions)

          const usersState = getTableState(tableName, 'users')
          if (usersState) {
            setSelectedUsers(JSON.parse(usersState))
          } else {
            setSelectedUsers(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }

    const feetchPeriodsData = async () => {
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods.reverse())
          setPeriodValue(
            periodListResponse.data.periods
              .find((period) => period.isActivated)
              ?.id.toString() || '',
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchUsersData()
    fetchCompaniesData()
    feetchPeriodsData()
  }, [t])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)
          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const page = ++pageIndex
            const storesResponse = await StoreService.getStores(
              selectedCompanies.map((company) => {
                return {
                  id: company.value,
                }
              }),
              selectedUsers.map((user) => ({ id: user.value })),
              searchValue,
              sortColumn,
              sortDirection,
              pageSize,
              page,

              visibilityValue === 'all'
                ? null
                : visibilityValue === '0'
                ? false
                : true,
              statusValue === 'all' ? null : statusValue === '0' ? false : true,
            )

            if (storesResponse.data.stores) {
              setTableColumns(generateTableColumns(storesResponse.data.stores))

              setFilteredStoresList(storesResponse.data.stores)

              setTotalCount(storesResponse.data.totalCount)
              setPageCount(Math.ceil(storesResponse.data.totalCount / pageSize))
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredStoresList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [
      t,
      selectedCompanies,
      selectedUsers,
      searchValue,
      generateTableColumns,
      statusValue,
      visibilityValue,
    ],
  )

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'companies', JSON.stringify(companies))
    setSelectedCompanies(companies)
  }
  const handleSelectedUsersChange = (users: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'users', JSON.stringify(users))
    setSelectedUsers(users)
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const storesResponse = await StoreService.getStores(
        selectedCompanies.map((company) => {
          return {
            id: company.value,
          }
        }),
        selectedUsers.map((user) => ({ id: user.value })),
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
        visibilityValue === 'all'
          ? null
          : visibilityValue === '0'
          ? false
          : true,
        statusValue === 'all' ? null : statusValue === '0' ? false : true,
      )

      const dataStores = storesResponse.data.stores
      if (dataStores) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'companyId',
            'companyName',
            'userCentralId',
            'userEmail',
            'userFirstname',
            'userLastname',
            'centralId',
            'internalId',
            'name',
            'city',
            'street',
            'homeNumber',
            'postalCode',
            'provinceName',
            'taxNumber',
            'saleBranchName',
            'saleTypesName',
            'isActive',
            'isVisible',
            'saleAreaName',
            'detail1',
            'detail2',
            'storeCategoryName',
            'storeGroupName',
            'modified',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredStoresData = dataStores.map((store) => {
          store.isActive = store.isActive
            ? t('pages.stores.statuses.active').toString()
            : t('pages.stores.statuses.inactive').toString()
          store.isVisible = store.isVisible
            ? t('pages.stores.statuses.visible').toString()
            : t('pages.stores.statuses.invisible').toString()

          return pick(store, visibleColumns)
        })

        const translatedHeaders = {
          companyId: t('excel.stores.companyId'),
          companyName: t('excel.stores.companyName'),
          userCentralId: t('excel.stores.userCentralId'),
          userEmail: t('excel.stores.username'),
          userFirstname: t('excel.stores.userFirstname'),
          userLastname: t('excel.stores.userLastname'),
          centralId: t('excel.stores.centralId'),
          internalId: t('excel.stores.internalId'),
          name: t('excel.stores.name'),
          countryName: t('excel.stores.countryName'),
          city: t('excel.stores.city'),
          street: t('excel.stores.street'),
          homeNumber: t('excel.stores.homeNumber'),
          postalCode: t('excel.stores.postalCode'),
          provinceName: t('excel.stores.provinceName'),
          taxNumber: t('excel.stores.taxNumber'),
          saleBranchName: t('excel.stores.saleBranchName'),
          saleTypesName: t('excel.stores.saleTypesName'),
          isActive: t('excel.stores.isActive'),
          isVisible: t('excel.stores.isVisible'),
          saleAreaName: t('excel.stores.saleAreaName'),
          detail1: t('excel.stores.detail1'),
          detail2: t('excel.stores.detail2'),
          storeCategoryName: t('excel.stores.storeCategoryName'),
          storeGroupName: t('excel.stores.storeGroupName'),
          modified: t('excel.stores.modified'),
        }

        const headers = [
          Object.keys(filteredStoresData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredStoresData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            {(isCentralAdmin(user) || isSuperAdmin(user)) && (
              <>
                <PrimaryButton
                  variant="contained"
                  onClick={() => handleImportDialogClickOpen('stores')}
                  style={{
                    marginRight: 5,
                    marginTop: '8px',
                    marginBottom: '7px',
                  }}
                >
                  {t('pages.stores.importStores')}
                </PrimaryButton>
                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  padding={'5px'}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: 5,
                  }}
                >
                  <PrimaryButton
                    variant="contained"
                    onClick={() =>
                      handleImportDialogClickOpen('store_period', periodValue)
                    }
                    style={{ marginRight: 5 }}
                  >
                    {t('pages.stores.updateStorePeriods')}
                  </PrimaryButton>
                  {t('pages.stores.forPeriod')}
                  <FormControl size="small">
                    <Select
                      id="period-select"
                      onChange={(event: SelectChangeEvent) => {
                        setPeriodValue(event.target.value)
                      }}
                      value={periodValue}
                      defaultValue={periodValue}
                      style={{ marginLeft: 5 }}
                    >
                      {periods.map((period) => (
                        <MenuItem value={period.id} key={period.id}>
                          {period.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </>
            )}
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('stores')}
              sx={{ marginLeft: 'auto', marginTop: '8px', marginBottom: '7px' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <StoresToolbar
            user={user}
            companies={companies}
            selectedCompanies={selectedCompanies}
            users={users}
            selectedUsers={selectedUsers}
            value={searchText}
            statusValue={statusValue}
            visibilityValue={visibilityValue}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            setSelectedCompanies={(companies) =>
              handleSelectedCompaniesChange(companies)
            }
            setSelectedUsers={(users: Option[]) => {
              handleSelectedUsersChange(users)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            filterStatus={(event: SelectChangeEvent) => {
              setStatusValue(event.target.value)
              setTableState(tableName, 'status', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            filterVisibility={(event: SelectChangeEvent) => {
              setVisibilityValue(event.target.value)
              setTableState(tableName, 'visibility', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredStoresList}
            height="calc(100vh - 330px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'internalId',
              'countryName',
              'provinceName',
              'taxNumber',
              'saleBranchName',
              'saleTypesName',
              'isActive',
              'saleAreaName',
              'storeCategoryName',
              'storeGroupName',
              'detail1',
              'detail2',
              'modified',
            ]}
            toggleVisibility={setColumnsVisibility}
          />
          {importName && (
            <ImportDialog
              open={openImportDialog}
              handleClose={handleImportDialogClose}
              name={importName}
              param1={importParam}
              title={importTitle}
            />
          )}
          {storeImageId && (
            <StoreImageDialog
              open={storeImageDialogOpen}
              handleClose={handleStoreImageDialogClose}
              imageId={storeImageId}
            />
          )}
        </>
      )}
    </>
  )
}

export default StoresList

import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../styles/Buttons/SecondaryButton'
import GenericImport from '../Pages/Import/partials/GenericImport'

type ImportDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  name: string
  title?: string
  param1?: string | number
  param2?: string | number
}

const ImportDialog: React.FunctionComponent<ImportDialogProps> = ({
  open,
  handleClose,
  name,
  title,
  param1,
  param2,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => handleClose(false)}
    >
      {title && (
        <DialogTitle>
          <Typography component={'div'} variant="body1" fontWeight="bold">
            {title}
          </Typography>
          <Typography component={'div'} variant="caption">
            {t('import.generic.subtitle')}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <GenericImport name={name} param1={param1} param2={param2} />
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="flex-end" width="100%">
          <SecondaryButton onClick={() => handleClose(false)}>
            {t('common.close')}
          </SecondaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default ImportDialog

import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import ChallengeStep1Form from '../partials/steps/ChallengeStep1Form'
import ChallengeStep2Form from '../partials/steps/ChallengeStep2Form'
import ChallengeStep4Form from '../partials/steps/ChallengeStep4Form'
import { useParams } from 'react-router-dom'
import ChallengeService from '../../../../services/challenge.service'
import {
  ChallengeDetails,
  ChallengeLevels,
  ChallengeParameters,
} from '../../../../store/Challenge/types'
import ChallengeStep3Form from '../partials/steps/ChallengeStep3Form'
import ChallengeStep5Form from '../partials/steps/ChallengeStep5Form'
import BottomBar from '../partials/BottomBar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useTranslation } from 'react-i18next'
// import moment from 'moment'

type ChallengeUpdateProps = {}
type ChallengeParams = {
  id: string
}

const ChallengeUpdate: FunctionComponent<ChallengeUpdateProps> = () => {
  const { t } = useTranslation()
  let { id } = useParams<ChallengeParams>()
  const [challengeId, setChallengeId] = useState<number | null>(null)
  const [challengeUnitId, setChallengeUnitId] = useState<number | null>(null)
  const [challengeDetails, setChallengeDetails] =
    useState<ChallengeDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [isStep2Saved, setIsStep2Saved] = useState<boolean>(true)
  const [isStep3Saved, setIsStep3Saved] = useState<boolean>(true)
  const [isStep4Saved, setIsStep4Saved] = useState<boolean>(true)
  const [challengeLevels, setChallengeLevels] =
    useState<ChallengeLevels | null>(null)
  const [challengeParameters, setChallengeParameters] =
    useState<ChallengeParameters | null>(null)
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false)
  const [refreshTimer, setRefreshTimer] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      setChallengeId(parseInt(id))
      try {
        const challengeDetailsResponse =
          await ChallengeService.getChallengeDetails(parseInt(id))
        if (challengeDetailsResponse.data) {
          setChallengeDetails(challengeDetailsResponse.data)
          setChallengeUnitId(challengeDetailsResponse.data.unitId)
        }
        const challengeParametersResponse =
          await ChallengeService.getChallengeParameters()

        if (challengeParametersResponse.data) {
          setChallengeParameters(challengeParametersResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, t, refreshTimer])

  if (!challengeDetails) return null

  return (
    <Box>
      {loading && <LoadingSpinner />}
      {!loading && challengeParameters !== null && (
        <Box sx={{ maxWidth: 800, margin: '0 auto 100px auto' }}>
          <ChallengeStep1Form
            challengeId={challengeId}
            onSetChallengeId={setChallengeId}
            challengeDetails={challengeDetails}
            challengeParameters={challengeParameters}
            setRefreshTimer={setRefreshTimer}
          />
          <ChallengeStep2Form
            challengeId={challengeId}
            challengeDetails={challengeDetails}
            setIsStep2Saved={setIsStep2Saved}
            challengeParameters={challengeParameters}
          />
          <ChallengeStep3Form
            challengeId={challengeId}
            challengeDetails={challengeDetails}
            setChallengeUnitId={setChallengeUnitId}
            isStep2Saved={isStep2Saved}
            challengeParameters={challengeParameters}
            setIsStep3Saved={setIsStep3Saved}
            isSaveDisabled={isSaveDisabled}
          />
          <ChallengeStep4Form
            challengeId={challengeId}
            challengeUnitId={challengeUnitId}
            challengeDetails={challengeDetails}
            isStep3Saved={isStep3Saved}
            setIsStep4Saved={setIsStep4Saved}
            setChallengeLevels={setChallengeLevels}
            isSaveDisabled={isSaveDisabled}
          />
          <ChallengeStep5Form
            challengeId={challengeId}
            challengeLevels={challengeLevels}
            isStep4Saved={isStep4Saved}
            isSaveDisabled={isSaveDisabled}
          />
        </Box>
      )}
      <BottomBar
        challengeId={challengeId}
        challengeDetails={challengeDetails}
        setIsSaveDisabled={setIsSaveDisabled}
        refreshTimer={refreshTimer}
        setRefreshTimer={setRefreshTimer}
      />
    </Box>
  )
}

export default ChallengeUpdate

import React from 'react'
import { PlotData } from '../../../store/Game/types'
import Map0Img from '../../../assets/images/game/map/0.png'
import Map1Img from '../../../assets/images/game/map/1.png'
import Map2Img from '../../../assets/images/game/map/2.png'
import Map3Img from '../../../assets/images/game/map/3.png'
import Map4Img from '../../../assets/images/game/map/4.png'
import Map5Img from '../../../assets/images/game/map/5.png'
import Map6Img from '../../../assets/images/game/map/6.png'
import Map7Img from '../../../assets/images/game/map/7.png'
import Map8Img from '../../../assets/images/game/map/8.png'
import Map9Img from '../../../assets/images/game/map/9.png'
import Map10Img from '../../../assets/images/game/map/10.png'
import Map11Img from '../../../assets/images/game/map/11.png'
import Map12Img from '../../../assets/images/game/map/12.png'
import Map13Img from '../../../assets/images/game/map/13.png'
import Map14Img from '../../../assets/images/game/map/14.png'
import Map15Img from '../../../assets/images/game/map/15.png'
import Map16Img from '../../../assets/images/game/map/16.png'
import Map17Img from '../../../assets/images/game/map/17.png'
import Map18Img from '../../../assets/images/game/map/18.png'

import Plot0Img from '../../../assets/images/game/plot/0.png'
import Plot1Img from '../../../assets/images/game/plot/1.png'
import Plot2Img from '../../../assets/images/game/plot/2.png'
import Plot3Img from '../../../assets/images/game/plot/3.png'
import Plot4Img from '../../../assets/images/game/plot/4.png'
import Plot5Img from '../../../assets/images/game/plot/5.png'
import Plot6Img from '../../../assets/images/game/plot/6.png'
import Plot7Img from '../../../assets/images/game/plot/7.png'
import Plot8Img from '../../../assets/images/game/plot/8.png'
import Plot9Img from '../../../assets/images/game/plot/9.png'
import Plot10Img from '../../../assets/images/game/plot/10.png'
import Plot11Img from '../../../assets/images/game/plot/11.png'
import Plot12Img from '../../../assets/images/game/plot/12.png'
import Plot13Img from '../../../assets/images/game/plot/13.png'
import Plot14Img from '../../../assets/images/game/plot/14.png'
import Plot15Img from '../../../assets/images/game/plot/15.png'
import Plot16Img from '../../../assets/images/game/plot/16.png'
import Plot17Img from '../../../assets/images/game/plot/17.png'
import Plot18Img from '../../../assets/images/game/plot/18.png'

export const NUM_OF_LEVELS_PER_MAP = 1

export const MAP_STYLE = {
  position: 'relative',
  margin: '5px',
} as React.CSSProperties

export const MAP_IMG_STYLE = {
  height: '530px',
} as React.CSSProperties

export const MAPS_DATA = [
  {
    mapSrc: Map0Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map1Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map2Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map3Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map4Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map5Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map6Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map7Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map8Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map9Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map10Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map11Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map12Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map13Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map14Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map15Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map16Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map17Img,
    levelPositions: [{ top: 120, left: 235 }],
    levels: [],
  },
  {
    mapSrc: Map18Img,
    levelPositions: [{ top: 390, left: 235 }],
    levels: [],
  },
]

export const PLOT_DATA: PlotData[] = [
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot0Img,
    title: 'Rozpływająca się w ustach kaczka po zamojsku',
    content:
      'Wyruszasz z Zamościa. Przed długą trasą solidnie się posilasz. Próbujesz kaczki po zamojsku. Potrawa ta wywodzi się z mieszania się kultury polskiej i żydowskiej.<br>Co ciekawe, kaczka według tego tradycyjnego przepisu faszerowana jest...drugą kaczką, ale nie tylko. Do farszu dodaje się też suszone śliwki, rodzynki, włoskie orzechy, majeranek i rozmaryn.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot1Img,
    title: 'Sycące prażuchy z Opola',
    content:
      'W Opolu poznajesz smak tradycyjnych pranzunków. To staropolskie danie przyrządza się z ziemniaków i mąki. Jest potrawą sycącą, popularną w wielu domach. Najczęściej serwowana jest z okrasą, z dodatkiem smażonego boczku i cebuli. Popijasz je szklanką kwaśnego mleka.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot2Img,
    title: 'Głęboki aromat serów łomnickich',
    content:
      'Kolej na Jelenią Górę. W pobliżu wytwarzane są jedne z najstarszych serów zagrodowych w Polsce. Sery łomnickie z Koziej Łąki mają certyfikat produktu regionalnego. <br>Z przyjemnością próbujesz wszystkich oferowanych gatunków.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot3Img,
    title: 'Przepyszna warszawska wuzetka',
    content:
      'Ciekawe czy wiesz, że to słynne ciastko powstało jako efekt konkursu? Czekoladowy biszkopt przełożony kremem stał się bezapelacyjnie jednym z najbardziej rozpoznawalnych symboli stolicy. Geneza nazwy tego deseru, choć nie do końca jasna, wskazuje na warszawską trasę W-Z. Być może jednak wuzetka to skrót od WZK, czyli wypieku z kremem. Niezależnie od tego skąd pochodzi jej nazwa, wuzetka jest niekwestionowaną królową stołecznych deserów. Nie możesz jej się oprzeć i kilka WZ-tek zabierasz w dalszą drogę!',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot4Img,
    title: 'Odżywczy miód wrzosowy',
    content:
      'Docierasz do Wrześni. Nazwa miasta pochodzi od wrzosu. To świetna okazja aby spróbować jak smakuje miód wrzosowy.<br>Dodajesz go do naparu z suszonych kwiatów tej rośliny. Rozgrzewający napój ma także właściwości lecznicze. Na zdrowie :-)',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot5Img,
    title: 'Lubuskie owoce z winem',
    content:
      'Będąc w Zielonej Górze odwiedzasz lokalną winnicę. Słuchając winiarskich opowieści i degustując regionale produkty przekonujesz się dlaczego miasto to nazywane jest Polską Toskanią. Szczególnie smakują Ci śliwki z dodatkiem czerwonego wina. Ten lokalny smakołyk naprawdę pozytywnie Cię zaskakuje.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot6Img,
    title: 'Tradycyjne gomółki rzeszowskie',
    content:
      'Kolejny przystanek - Rzeszów. Odwiedzisz Rzeszowski Festiwal Kulinarny. Poznasz mało znany regionalny przysmak. Tradycyjne podkarpackie gomółki czyli suszone twarożki. Wytwarzane są z sera i jajek, z dodatkiem kminku i soli. Są słone i pięknie pachną. Zjadasz je ze smakiem.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot7Img,
    title: 'Gorące cieszyńskie pieczoki',
    content:
      'Odwiedzasz Bielsko-Białą. Pieczoki to regionalny przysmak popularny na Śląsku Cieszyńskim. Można przypuszczać, że rozpropagowali go pasterze wiejscy, którzy esienią rozpalali ogniska, żeby się ogrzać, a do żaru wrzucali bulwy ziemniaków. Zwyczaj pieczenia ziemniaków jest bardzo stary, były najprostszą i podstawową potrawą już 150 lat temu. Po długiej podróży też z chęcią zatopisz zęby w pieczonym ziemniaku podanym z masłem i solą.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot8Img,
    title: 'Chrupiący krakowski obwarzanek',
    content:
      'Docierasz do Rynku Starego Miasta w Krakowie. Czujesz zapach świeżo wypiekanych obwarzanków. Masz do wyboru te z makiem, sezamem lub solą. Decydujesz się odwiedzić Muzeum Obwarzanka, tam skosztujesz wszystkich dostępnych wersji tego regionalnego wypieku.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot9Img,
    title: 'Niesamowita mieszanka smaków białostockiej buzy',
    content:
      'Tym razem odwiedzasz Białystok. Masz ochotę napić się czegoś wyjątkowego? Świetnie się składa. Buza to wyjątkowy napój dostępny na Bałkanach i w …Białymstoku. Jest napojem bezalkoholowym, fermentowanym na bazie kaszy jaglanej. Podaje się ją mocno schłodzoną z rodzynkami. W smaku przypomina podpiwek. Gasisz pragnienie i ruszasz dalej.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot10Img,
    title: 'Tradycyjne dzyndzałki z Olsztyna',
    content:
      'Tym razem odwiedzasz Olsztyn. Zaczynasz odczuwać głód, więc z chęcią dowiesz się jak smakują dzyndzałki warmińskie. Ten regionalny przysmak to wyśmienite pierogi nadziewane farszem z kaszy gryczanej i jajka na twardo, z dodatkiem smażonego wędzonego boczku z cebulą. Podane tradycyjnie z gęstą śmietaną i skwarkami. Smacznego!',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot11Img,
    title: 'Wytrawna ryba w zalewnie octowej',
    content:
      'Jesteś w Gorzowie. Ten zalesiony region jest bogaty w dziczyznę, grzyby i jagody. Ma też jeziora pełne ryb. To właśnie ryby w zalewie octowej trafiły na listę produktów tradycyjnych. Z chęcią skosztujesz tej lokalnej potrawy obecnej na stołach podczas świątecznych uroczystości.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot12Img,
    title: 'Gorąca kielecka zalewajka',
    content:
      'Docierasz do Kielc, miasta na Szlaku Świętokrzyskiej Kuźni Smaków. Masz ochotę na rozgrzewającą zupę. Rosół i pomidorowa ustępują zalewajce. Ta regionalna królowa zup jest przygotowywana na bazie kwaśnego żuru, leśnych grzybów oraz skwarek. W bogatszej wersji chowa się pod jajkiem sadzonym, które wzbogaca smak zupy. Właśnie taką wersję zamawiasz..',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot13Img,
    title: 'Drożdżowy aromat pasztecika szczecińskiego',
    content:
      'Witaj w Szczecinie. To tu w październiku przypada święto pasztecika szczecińskiego. Ty jednak skosztujesz go już dziś. Słynny w regionie pasztecik to nic innego jak smażony w głębokim tłuszczu podłużny kawałek ciasta drożdżowego z mięsnym nadzieniem w środku.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot14Img,
    title: 'Rozgrzewający lubelski forszmak',
    content:
      'Odwiedzasz Lublin. Posilisz się aromatyczną zupą o zawiesistej konsystencji. Zawiera ogórki kiszone, mięso drobiowe, szynkę i wędzony boczek. Całość zaprawiona jest koncentratem pomidorowym. Będzie podana tradycyjne, w wydrążonym bochenku chleba.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot15Img,
    title: 'Pożywna zupa rybna',
    content:
      'Jesteś w Parku Oliwskim w Gdańsku gdzie od lat organizowane są tradycyjne festiwale kulinarne i konkursy na potrawy tradycyjne. Spróbujesz jednej z nich. Wybornej zupy rybnej przyrządzanej z różnych gatunków ryb morskich takich jak dorsz czy łosoś z dodatkiem szyjek rakowych.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot16Img,
    title: 'Bogaty w nienasycone kwasy tłuszczowe wielkopolski olej rydzowy',
    content:
      'Czas na Poznań. Odwiedzisz lokalną manufakturę specjalizującą się wytłaczaniu na zimno oleju rydzowego. Produkowany jest z nasion lnianki ozimej, znanej od wieków rośliny oleistej uprawianej w Wielkopolsce. Olej rydzowy charakteryzuje korzenny smak z wyraźną nutą cebuli i gorczycy. Jest bardzo zdrowy i stanowi wyśmienity dodatek do kasz, ziemniaków i warzywnych sałatek. Zaopatrzony w butelkę oleju jedziesz dalej.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '100px',
      left: 'calc(50% - 80px)',
    },
    image: Plot17Img,
    title: 'Korzenny smak toruńskiego piwa piernikowego',
    content:
      'Kolej na Toruń. Odwiedzisz regionalny browar staromiejski. Na własne oczy zobaczysz proces powstawania złocistego trunku. Będziesz mieć okazję porozmawiać z piwowarem i skosztować świeżo ważonego piwa piernikowego prosto z tanka.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot18Img,
    title: 'Centrala - finał',
    content:
      'Kliknij ikonę ze strzałką, aby wejść do biura i spotkać się z Prezesem Grupy MPT, który sprawdzi czy udało Ci się odwiedzić wszystkie miasta, zebrać z nich pamiątki oraz dowiedzieć się coś więcej na ich temat 🙂.<br>Odpowiedzi na te pytania nie powinny sprawić Ci większego problemu. Musisz jednak zdać ten „egzamin” aby otrzymać nagrody! Pamiętaj tylko, że jeśli się pomylisz, to musisz poczekać z kolejną próbą do następnego dnia. Sprawdzenie odpowiedzi to określony koszt Punktów Paliwa. Szczegóły przedstawi Ci Prezes 🙂.',
  },
]

import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import ImportService from '../../../../services/import.service'
import { Stack } from '@mui/system'
import { Card, Grid, Typography } from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { toast } from 'react-toastify'

const ImportFromFTP: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [setLoading])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const startFtpImport = async (importType: string) => {
    try {
      setIsSaving(true)
      let importFromFtpResponse
      switch (importType) {
        case 'units':
          importFromFtpResponse =
            await ImportService.importOsUnitResultsFromFTP()
          break
        case 'plans':
          importFromFtpResponse = await ImportService.importPlanResultsFromFTP()
          break
        case 'stores':
          importFromFtpResponse = await ImportService.importStoresFromFTP()
          break
      }

      if (importFromFtpResponse && importFromFtpResponse.data.success) {
        toast.success(t('messages.success.importSuccessful'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Stack p={3}>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Card style={{ padding: '20px' }}>
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                fontSize: '20px',
                fontWeight: 'bolder',
                marginBottom: '20px',
              }}
            >
              {t('pages.configuration.importFromFtp.title')}
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <PrimaryButton
                style={{ marginRight: '10px' }}
                onClick={() => {
                  startFtpImport('units')
                }}
                disabled={isSaving}
              >
                {t('pages.configuration.importFromFtp.startImportLabel')}
              </PrimaryButton>
              <Typography variant="subtitle2" gutterBottom>
                {t('pages.configuration.importFromFtp.importUnitsLabel')}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <PrimaryButton
                style={{ marginRight: '10px' }}
                onClick={() => {
                  startFtpImport('plans')
                }}
                disabled={isSaving}
              >
                {t('pages.configuration.importFromFtp.startImportLabel')}
              </PrimaryButton>
              <Typography variant="subtitle2" gutterBottom>
                {t('pages.configuration.importFromFtp.importPlansResults')}
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <PrimaryButton
                style={{ marginRight: '10px' }}
                onClick={() => {
                  startFtpImport('stores')
                }}
                disabled={isSaving}
              >
                {t('pages.configuration.importFromFtp.startImportLabel')}
              </PrimaryButton>
              <Typography variant="subtitle2" gutterBottom>
                {t('pages.configuration.importFromFtp.importStores')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </Stack>
  )
}

export default ImportFromFTP

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import GameService from '../../../services/game.service'
import { TradeActionBlockValue } from '../../../store/Game/types'
import LoadingSpinner from '../../shared/LoadingSpinner'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {
  thousandsSeparator
} from '../../../helpers/utils'
import { ReactComponent as SearchInput } from '../../../assets/images/phone/search_input.svg'
import { ReactComponent as FilterButton } from '../../../assets/images/phone/filter_button.svg'
import { Stack } from '@mui/system'
import _ from 'lodash'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '10px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#e4e4e4',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

type BlockContentProps = {
  blockId: number
  userId: number
}

const BlockContent: React.FC<BlockContentProps> = ({ blockId, userId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [blockData, setBlockData] = useState<TradeActionBlockValue[]>([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const blockResponse = await GameService.getTradeActionBlock(
          blockId,
          userId,
        )
        setBlockData(blockResponse.data.blockValues)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, blockId, userId])

  const renderParameterValue = (value: string, type: string) => {
    switch (type) {
      case 'NUM':
        return thousandsSeparator(parseFloat(value))
      case 'PRC':
        return thousandsSeparator(parseFloat(value), true) + '%'
      case 'VAL':
        return thousandsSeparator(parseFloat(value), true)
      default:
        return value
    }
  }

  const sortByParameterId = (
    obj: TradeActionBlockValue,
  ): TradeActionBlockValue => {
    const order: any[] = [],
      res: any = {}
    Object.keys(obj).forEach((key) => {
      return (order[(obj as any)[key]['parameterId'] - 1] = key)
    })
    order.forEach((key) => {
      res[key] = obj[key]
    })
    return res
  }

  return (
    <div style={{ margin: '16px' }}>
      {loading && <LoadingSpinner />}
      {!loading &&
        blockData.length === 0 &&
        t('pages.game.phone.screens.actionsScreen.noData')}
      {!loading && blockData.length > 0 && (
        <>
          <Stack
            display="flex"
            alignItems="center"
            flexDirection="row"
            mb="16px"
          >
            <FilterButton />
            <SearchInput style={{ marginLeft: '16px' }} />
          </Stack>
          {blockData.map((block, i) => {
            const sortedBlock = sortByParameterId(block)
            return (
              <Table
                key={`block-${blockId}-${i}`}
                sx={{ marginBottom: '20px', backgroundColor: '#fff' }}
              >
                <TableBody>
                  {Object.entries(
                    _.isEmpty(sortedBlock) ? block : sortedBlock,
                  ).map((entry) => (
                    <StyledTableRow
                      key={`entry-${blockId}-${
                        entry[1].parameterId
                          ? entry[1].parameterId
                          : Math.random()
                      }`}
                    >
                      <StyledTableCell component="th" scope="row">
                        {entry[0]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <strong>
                          {renderParameterValue(
                            entry[1].parameterValue,
                            entry[1].parameterType,
                          )}
                        </strong>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            )
          })}
        </>
      )}
    </div>
  )
}

export default BlockContent

import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  ChallengeDetails,
  ChallengeParameters,
  ChallengeStep3ManufacturerRequestParams,
  ChallengeStep3ProductRequestParams,
  ChallengeUnit,
  ManufacturerAmount,
  ProductAmount,
} from '../../../../../store/Challenge/types'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import ChallengeService from '../../../../../services/challenge.service'
import ManufacturerService from '../../../../../services/manufacturer.service'
import { Manufacturer } from '../../../../../store/Manufacturer/types'
import { toast } from 'react-toastify'
import { isNumber } from 'lodash'

import { grey } from '@mui/material/colors'
import { StepContainer } from './styles'
import theme from '../../../../../theme'
import AddIcon from '@mui/icons-material/Add'
import ChallengeImportProducts from './ChallengeImportProducts'

import { findDuplicatedProductCodes } from '../../../../../helpers/utils'

type ChallengeStep3FormProps = {
  challengeId: number | null
  challengeDetails?: ChallengeDetails
  setChallengeUnitId: React.Dispatch<React.SetStateAction<number | null>>
  isStep2Saved: boolean
  setIsStep3Saved: React.Dispatch<React.SetStateAction<boolean>>
  challengeParameters: ChallengeParameters
  isSaveDisabled: boolean
}

const ChallengeStep3Form: React.FunctionComponent<ChallengeStep3FormProps> = ({
  challengeId,
  challengeDetails,
  setChallengeUnitId,
  isStep2Saved,
  setIsStep3Saved,
  challengeParameters,
  isSaveDisabled,
}) => {
  const { t } = useTranslation()
  const [loadingStep3, setLoadingStep3] = useState<boolean>(true)
  const [productsMinimum, setProductsMinimum] = useState<number>(0)
  const [units, setUnits] = useState<ChallengeUnit[]>([])
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
  const [activeChallengeUnit, setActiveChallengeUnit] =
    useState<ChallengeUnit | null>(null)
  const [manufacturersMinimum, setManufacturersMinimum] = useState<number>(0)
  const [savingStep3, setSavingStep3] = useState<boolean>(false)

  const emptyProduct = {
    ean: '',
    name: '',
    qty: 0,
    error: false,
  }

  const emptyManufacturer = {
    manufacturerId: 0,
    requiredAmount: 0,
    error: false,
  }

  const [products, setProducts] = useState<ProductAmount[]>([emptyProduct])
  const [manufacturersAmount, setManufacturersAmount] = useState<
    ManufacturerAmount[]
  >([emptyManufacturer])

  const resetStep3Form = () => {
    setActiveChallengeUnit(null)
    setProducts([emptyProduct])
    setManufacturersAmount([emptyManufacturer])
    setProductsMinimum(0)
    setManufacturersMinimum(0)
  }

  const saveStep3Form = async () => {
    let hasErrors = false
    if (
      activeChallengeUnit &&
      ['SP', 'QV', 'OS'].includes(activeChallengeUnit.code)
    ) {
      const updatedProducts = products.map((product) => {
        if (
          product.ean.length < challengeParameters.unitProductCodeMinLength ||
          product.ean.length > challengeParameters.unitProductCodeMaxLength ||
          product.name.trim().length <
            challengeParameters.unitProductNameMinLength ||
          product.name.trim().length >
            challengeParameters.unitProductNameMaxLength ||
          product.qty < 0
        ) {
          hasErrors = true
          return {
            ...product,
            error: true,
          }
        }
        return {
          ...product,
          error: false,
        }
      })
      setProducts((prevProduts) => [...updatedProducts])
      const requiredQtySum = products.reduce(function (acc, obj) {
        return acc + obj.qty
      }, 0)
      if (
        productsMinimum < requiredQtySum ||
        !isNumber(productsMinimum) ||
        Number.isNaN(productsMinimum)
      ) {
        toast.error(t('pages.challenge.step3.wrongMinimalTotalAmount'))
      }
      if (!hasErrors && challengeId && productsMinimum >= requiredQtySum) {
        const formParams: ChallengeStep3ProductRequestParams = {
          challengeId,
          challengeUnitId: activeChallengeUnit.id,
          minimalTotalAmount: productsMinimum,
          products: products.map((product) => {
            return {
              productCode: product.ean,
              productName: product.name,
              requiredAmount: product.qty,
            }
          }),
        }

        try {
          setSavingStep3(true)

          let duplicatedProducts = findDuplicatedProductCodes(
            formParams.products,
          )

          if (duplicatedProducts.length === 0) {
            for (let i: number = 0; i < formParams.products.length; i++) {
              const checkChallengeProductCodeResponse =
                await ChallengeService.checkChallengeProductCode(
                  formParams.products[i].productCode,
                )
              if (!checkChallengeProductCodeResponse.data.isValid) {
                toast.error(
                  t('pages.challenge.step3.errors.productsCodeValidation', {
                    invalidProductCode: formParams.products[i].productCode,
                  }),
                )
                break
              }
            }
            const createOrUpdateChallengeStep3ProductResponse =
              await ChallengeService.updateChallengeStep3Product(formParams)
            if (createOrUpdateChallengeStep3ProductResponse.data.success) {
              toast.success(t('messages.success.savedSuccessfully'))
              setIsStep3Saved(true)
            }
          } else {
            toast.error(
              t('pages.challenge.step3.errors.duplicatedProducts', {
                duplicatedProducts: duplicatedProducts.map(
                  (product: string) => ' ' + product,
                ),
              }),
            )
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSavingStep3(false)
        }
      }
    } else if (
      activeChallengeUnit &&
      ['SM', 'AV'].includes(activeChallengeUnit.code)
    ) {
      const updatedManufacturers = manufacturersAmount.map((manufacturer) => {
        if (
          manufacturer.manufacturerId === 0 ||
          manufacturer.requiredAmount < 0
        ) {
          hasErrors = true
          return {
            ...manufacturer,
            error: true,
          }
        }
        return {
          ...manufacturer,
          error: false,
        }
      })
      setManufacturersAmount((prevState) => [...updatedManufacturers])
      const requiredQtySum = manufacturersAmount.reduce(function (acc, obj) {
        return acc + obj.requiredAmount
      }, 0)
      if (
        manufacturersMinimum < requiredQtySum ||
        !isNumber(manufacturersMinimum) ||
        Number.isNaN(manufacturersMinimum)
      ) {
        toast.error(t('pages.challenge.step3.wrongMinimalTotalAmount'))
      }
      if (!hasErrors && challengeId && manufacturersMinimum >= requiredQtySum) {
        const formParams: ChallengeStep3ManufacturerRequestParams = {
          challengeId,
          challengeUnitId: activeChallengeUnit.id,
          minimalTotalAmount: manufacturersMinimum,
          manufacturers: manufacturersAmount.map((m) => {
            return {
              manufacturerId: m.manufacturerId,
              requiredAmount: m.requiredAmount,
            }
          }),
        }

        try {
          setSavingStep3(true)
          const createOrUpdateChallengeStep3ManufacturerResponse =
            await ChallengeService.updateChallengeStep3Manufacturer(formParams)
          if (createOrUpdateChallengeStep3ManufacturerResponse.data.success) {
            toast.success(t('messages.success.savedSuccessfully'))
            setIsStep3Saved(true)
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSavingStep3(false)
        }
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unitListResponse = await ChallengeService.getChallengeUnitList()

        if (unitListResponse.data.challengeUnits) {
          const activeUnitList = unitListResponse.data.challengeUnits.filter(
            (unit) => unit.isVisible,
          )
          setUnits(activeUnitList)
        }

        const manufacturerListResponse =
          await ManufacturerService.getManufacturerList()

        if (manufacturerListResponse.data.manufacturers) {
          setManufacturers(manufacturerListResponse.data.manufacturers)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoadingStep3(false)
      }
    }
    fetchData()
  }, [t])

  useEffect(() => {
    // load data if update
    if (challengeDetails) {
      const activeUnit = units.find(
        (unit) => unit.id === challengeDetails.unitId,
      )
      if (activeUnit) {
        setActiveChallengeUnit(activeUnit)
        if (['SP', 'QV', 'OS'].includes(activeUnit.code)) {
          setProducts(
            challengeDetails.products.map((product) => {
              return {
                ean: product.productCode,
                name: product.productName,
                qty: product.requiredAmount,
                error: false,
              }
            }),
          )
          setProductsMinimum(challengeDetails.minimalTotalAmount)
        } else if (['SM', 'AV'].includes(activeUnit.code)) {
          setManufacturersAmount(
            challengeDetails.manufacturers.map((manufacturer) => {
              return {
                manufacturerId: manufacturer.manufacturerId,
                requiredAmount: manufacturer.requiredAmount,
                error: false,
              }
            }),
          )
          setManufacturersMinimum(challengeDetails.minimalTotalAmount)
        }
      }
    }
  }, [challengeDetails, units])

  const addNewProductRow = () => {
    if (products.length < challengeParameters.unitProductCodeLimit) {
      setProducts((prevProducts) => [...prevProducts, emptyProduct])
    }
  }

  const deleteProductRow = (rowId: number) => {
    products.splice(rowId, 1)
    setProducts((prevProducts) => [...products])
  }

  const handleProductEANChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedProducts = [...products]
    updatedProducts[rowId].ean = event.target.value
    setProducts(updatedProducts)
  }

  const handleProductQtyChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedProducts = [...products]
    updatedProducts[rowId].qty = parseInt(event.target.value)
    setProducts(updatedProducts)
  }

  const handleProductNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedProducts = [...products]
    updatedProducts[rowId].name = event.target.value
    setProducts(updatedProducts)
  }

  const handleProductsMinimumChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setProductsMinimum(parseInt(event.target.value))
  }

  const addNewManufacturerRow = () => {
    if (
      manufacturersAmount.length < challengeParameters.unitManufacturerCodeLimit
    ) {
      setManufacturersAmount((prevManufacturers) => [
        ...prevManufacturers,
        emptyManufacturer,
      ])
    }
  }

  const deleteManufacturerRow = (rowId: number) => {
    manufacturersAmount.splice(rowId, 1)
    setManufacturersAmount((prevManufacturers) => [...manufacturersAmount])
  }

  const handleManufacturerNameChange = (
    event: SelectChangeEvent<string>,
    rowId: number,
  ) => {
    const updatedManufacturers = [...manufacturersAmount]
    updatedManufacturers[rowId].manufacturerId = parseInt(event.target.value)
    setManufacturersAmount(updatedManufacturers)
  }

  const handleManufacturerTurnoverChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedManufacturers = [...manufacturersAmount]
    updatedManufacturers[rowId].requiredAmount = parseInt(event.target.value)
    setManufacturersAmount(updatedManufacturers)
  }

  const handleManufacturersMinimumChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setManufacturersMinimum(parseInt(event.target.value))
  }

  const handleUnitChange = (event: SelectChangeEvent) => {
    const activeChallenge = units.find(
      (unit) => unit.id === parseInt(event.target.value),
    )
    if (activeChallenge) {
      setActiveChallengeUnit(activeChallenge)
      setChallengeUnitId(activeChallenge.id)
    } else {
      setActiveChallengeUnit(null)
      setChallengeUnitId(null)
    }
  }

  if (!challengeId || !isStep2Saved) {
    return (
      <StepContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">3</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography variant="subtitle2" fontWeight={'bold'} component="div">
              {t('pages.challenge.step3.title')}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              dangerouslySetInnerHTML={{
                __html: t('pages.challenge.step3.subtitle'),
              }}
            ></Typography>
            <IconButton
              aria-label="delete"
              disabled
              size="large"
              className="add-button"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      </StepContainer>
    )
  }

  return (
    <StepContainer>
      <Stack direction={'row'}>
        <div className="step-line first">
          <div className="step-circle">3</div>
          <div className="step-arrow first"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <div className="step-title">
                {t('pages.challenge.step3.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.challenge.step3.subtitle')}
              </div>
            </Grid>
          </Grid>
          {loadingStep3 && <LoadingSpinner />}
          {!loadingStep3 && (
            <Box
              className="form-style"
              component="form"
              sx={{
                '& .MuiTextField-root': { mb: 1, mt: 1 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
              noValidate
              autoComplete="off"
            >
              <Stack flexDirection="row" alignItems="center" mb={2}>
                <FormControl style={{ minWidth: 360 }}>
                  <label>
                    {t('pages.challenge.step3.selectChallengeUnit')}
                  </label>
                  <Select
                    labelId="unit-select-label"
                    id="unit-select"
                    value={
                      activeChallengeUnit
                        ? activeChallengeUnit.id.toString()
                        : '0'
                    }
                    onChange={handleUnitChange}
                    size="small"
                  >
                    <MenuItem value={0} disabled>
                      {t('pages.challenge.step3.select')}
                    </MenuItem>
                    {units.map((unit) => (
                      <MenuItem key={unit.id} value={unit.id}>
                        {unit.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Typography variant="body2" gutterBottom>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      units.find((unit) => unit.id === activeChallengeUnit?.id)
                        ?.description || '',
                  }}
                />
              </Typography>
              {activeChallengeUnit && (
                <Grid container spacing={2} mt={1}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: ['SM', 'AV'].includes(activeChallengeUnit.code)
                        ? 'none'
                        : 'block',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="caption" fontWeight="bold">
                          {t(
                            'pages.challenge.step3.products.productsChallenge',
                            {
                              minProductsLength:
                                challengeParameters.unitProductCodeMinLength,
                              maxProductsLength:
                                challengeParameters.unitProductCodeMaxLength,
                            },
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="caption" fontWeight="bold">
                          {t('pages.challenge.step3.products.productName', {
                            productNameMinLength:
                              challengeParameters.unitProductNameMinLength,
                            productNameMaxLength:
                              challengeParameters.unitProductNameMaxLength,
                          })}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="caption" fontWeight="bold">
                          {t('pages.challenge.step3.products.requiredValue')}
                        </Typography>
                      </Grid>
                    </Grid>
                    {products.map((product, i) => (
                      <Grid container spacing={2} key={`product-${i}`} mb={1}>
                        <Grid item xs={4}>
                          <Stack direction="row" alignItems="flex-start">
                            <TextField
                              placeholder={t(
                                'pages.challenge.step3.products.formPlaceholder',
                              )}
                              variant="outlined"
                              size="small"
                              fullWidth
                              type="number"
                              value={product.ean}
                              onChange={(e) => handleProductEANChange(e, i)}
                              error={product.error}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={product.name}
                            error={product.error}
                            onChange={(e) => handleProductNameChange(e, i)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            type="number"
                            value={product.qty}
                            error={product.error}
                            onChange={(e) => handleProductQtyChange(e, i)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          {products.length >
                            challengeParameters.unitProductCodeRequiredAmount && (
                            <IconButton
                              aria-label="delete"
                              onClick={() => deleteProductRow(i)}
                            >
                              <DeleteForeverOutlinedIcon />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingTop: '0px !important',
                          }}
                        >
                          {product.error && (
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {t('pages.challenge.step3.products.error')}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    {products.length <
                      challengeParameters.unitProductCodeLimit && (
                      <div style={{ textAlign: 'center' }}>
                        <Stack
                          display="flex"
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          mb={1}
                          onClick={addNewProductRow}
                          style={{ cursor: 'pointer' }}
                        >
                          <IconButton
                            aria-label="add"
                            size="small"
                            style={{
                              backgroundColor: theme.colorsPalette.yellow.main,
                              marginRight: 10,
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            component="div"
                          >
                            {t('pages.challenge.step3.products.add', {
                              maxNumberOfProducts:
                                challengeParameters.unitProductCodeLimit,
                            })}
                          </Typography>
                        </Stack>
                        {t('pages.challenge.step3.or')}
                        <ChallengeImportProducts
                          challengeParameters={challengeParameters}
                          setProducts={setProducts}
                          setProductsMinimum={setProductsMinimum}
                        />
                      </div>
                    )}
                    <FormControl>
                      <label>
                        {t('pages.challenge.step3.products.minimum')}
                      </label>
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={productsMinimum}
                        onChange={handleProductsMinimumChange}
                        style={{
                          margin: 0,
                          width: '180px',
                        }}
                        error={productsMinimum < 0}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: ['SP', 'QV', 'OS'].includes(
                        activeChallengeUnit.code,
                      )
                        ? 'none'
                        : 'block',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="caption" fontWeight="bold">
                          {t(
                            'pages.challenge.step3.manufacturers.manufacturersChallenge',
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="caption" fontWeight="bold">
                          {t(
                            'pages.challenge.step3.manufacturers.requiredValue',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    {manufacturersAmount.map((manufacturer, i) => (
                      <Grid
                        container
                        spacing={2}
                        key={`manufacturer-${i}`}
                        mb={1}
                      >
                        <Grid
                          item
                          xs={8}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <FormControl fullWidth error={manufacturer.error}>
                            <Select
                              id="period-select"
                              value={manufacturer.manufacturerId.toString()}
                              onChange={(e) =>
                                handleManufacturerNameChange(e, i)
                              }
                              size="small"
                              fullWidth
                            >
                              <MenuItem value="0">
                                {t(
                                  'pages.challenge.step3.manufacturers.formPlaceholder',
                                )}
                              </MenuItem>
                              {manufacturers.map((m) => (
                                <MenuItem
                                  value={m.manufacturerId.toString()}
                                  key={m.manufacturerId}
                                >
                                  {m.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            type="number"
                            value={manufacturer.requiredAmount}
                            error={manufacturer.error}
                            onChange={(e) =>
                              handleManufacturerTurnoverChange(e, i)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          {manufacturersAmount.length >
                            challengeParameters.unitManufacturerCodeRequiredAmount && (
                            <IconButton
                              aria-label="delete"
                              onClick={() => deleteManufacturerRow(i)}
                            >
                              <DeleteForeverOutlinedIcon />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingTop: '0px !important',
                          }}
                        >
                          {manufacturer.error && (
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {t('pages.challenge.step3.manufacturers.error')}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    {manufacturersAmount.length <
                      challengeParameters.unitManufacturerCodeLimit && (
                      <Stack
                        display="flex"
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        mb={3}
                        onClick={addNewManufacturerRow}
                        style={{ cursor: 'pointer' }}
                      >
                        <IconButton
                          aria-label="add"
                          size="small"
                          style={{
                            backgroundColor: theme.colorsPalette.yellow.main,
                            marginRight: 10,
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          component="div"
                        >
                          {t('pages.challenge.step3.manufacturers.add', {
                            maxNumberOfManufacturersRows:
                              challengeParameters.unitManufacturerCodeLimit,
                          })}
                        </Typography>
                      </Stack>
                    )}
                    <FormControl>
                      <label>
                        {t('pages.challenge.step3.manufacturers.minimum')}
                      </label>

                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={manufacturersMinimum}
                        onChange={handleManufacturersMinimumChange}
                        style={{
                          margin: 0,
                          width: '160px',
                        }}
                        error={manufacturersMinimum < 0}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Stack
                className="buttons-container"
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                width="100%"
              >
                <SecondaryButton
                  onClick={resetStep3Form}
                  disabled={savingStep3}
                >
                  {t('common.reset')}
                </SecondaryButton>
                <PrimaryButton
                  onClick={saveStep3Form}
                  disabled={
                    !activeChallengeUnit ||
                    savingStep3 ||
                    isSaveDisabled ||
                    !challengeId ||
                    (challengeDetails && !challengeDetails?.isEditable)
                  }
                >
                  {t('common.save')}
                </PrimaryButton>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
    </StepContainer>
  )
}

export default ChallengeStep3Form

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Stack, AlertColor, CircularProgress } from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import ImportService from '../../../../services/import.service'
import { ImportParameterColumn } from '../../../../store/Import/types'
import { findDuplicates } from '../../../../helpers/utils'
import { getManufacturersResponse } from './messages/manufacturers'
import { getUserChallengePlansResponse } from './messages/userChallengePlans'
import { getUserChallengeResultsResponse } from './messages/userChallengeResults'
import { getStoresResponse } from './messages/stores'
import { getUserPlansResponse } from './messages/userPlans'
import { getStorePlanResultsResponse } from './messages/storePlanResults'
import { getStorePlansResponse } from './messages/storePlans'
import FileUploader from './FileUploader'
import { isNumber } from 'lodash'
import { getStorePeriodsResponse } from './messages/storePeriod'
import { getLoyaltyAwardConfirmationsResponse } from './messages/loyaltyAwardConfirmations'
import { getActionUsersResponse } from './messages/actionUsers'
import { getAssetUsersResponse } from './messages/assetUsers'
import { getChallengeSpResultsResponse } from './messages/challengeSpResults'
import { getChallengeOsResultsResponse } from './messages/challengeOsResults'
import { getOrdersResponse } from './messages/orders'
import { getProductsResponse } from './messages/products'
import { getUsersPointsOperationsResponse } from './messages/usersPointsOperations'
import moment from 'moment'

const { read, utils } = XLSX

type ImportPrepareProps = {
  additionalParams?: {}
  importParameters: ImportParameterColumn[]
  importName: string
  handleSetAcceptResponse: (
    acceptResponse: {
      message: {
        type: AlertColor
        message: string
        hasSecondStep: boolean
      }
      additionalInfo?: React.ReactNode
    } | null,
  ) => void
}

const ImportPrepare: React.FunctionComponent<ImportPrepareProps> = ({
  additionalParams,
  importParameters,
  importName,
  handleSetAcceptResponse,
}) => {
  const { t } = useTranslation()
  const [savingImport, setSavingImport] = useState<boolean>(false)
  const [importPrepareParams, setImportPrepareParams] = useState<any | null>(
    null,
  )
  const [alertOpen, setAlertOpen] = useState<boolean>(true)
  const [alertData, setAlertData] = useState({
    type: 'error' as AlertColor,
    message: '',
  })
  const [dataObj, setDataObj] = useState<any[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [fileLoading, setFileLoading] = useState<boolean>(false)

  const onFileDrop = (file: File) => {
    handleSetAcceptResponse(null)

    if (file) {
      setFile(file)
      const reader = new FileReader()
      reader.onload = function (e) {
        if (e && e.target) {
          var data = e.target.result
          let readedData = read(data, { type: 'binary', codepage: 65001 })
          const wsname = readedData.SheetNames[0]
          const ws = readedData.Sheets[wsname]

          /* Convert array to json*/
          const initialParse = utils.sheet_to_json<any>(ws, {
            blankrows: false,
            defval: null,
            header: 1,
            raw: false,
          })

          // build header array based on import parameters
          const header = initialParse[0].map((headerValue: any, i: number) => {
            const foundParam = importParameters.find((param) =>
              param.columnName.includes(headerValue),
            )
            if (foundParam) {
              return foundParam.fieldName
            }
            return i
          })

          // parse again with proper headers
          const dataParse = utils.sheet_to_json<any>(ws, {
            blankrows: false,
            defval: null,
            header,
            raw: false,
          })

          setDataObj(dataParse)
        }
      }
      reader.readAsBinaryString(file)
    }
  }

  const saveImport = async () => {
    if (importPrepareParams) {
      try {
        setSavingImport(true)
        switch (importName) {
          case 'manufacturers':
            const importResponse = await ImportService.prepareManufacturer(
              importPrepareParams,
            )

            if (importResponse.data.success) {
              const response = getManufacturersResponse(
                importResponse,
                'prepare',
                'warning',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'products':
            const productResponse = await ImportService.prepareProduct(
              importPrepareParams,
            )

            if (productResponse.data.success) {
              const response = getProductsResponse(
                productResponse,
                'prepare',
                'warning',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'stores':
            const importStoresResponse = await ImportService.prepareStore(
              importPrepareParams,
            )

            if (importStoresResponse.data.success) {
              const response = getStoresResponse(
                importStoresResponse,
                'prepare',
                'warning',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'user_challenge_plans':
            const userChallengePlansResponse =
              await ImportService.userChallengePlans(importPrepareParams)

            if (userChallengePlansResponse.data.success) {
              const response = getUserChallengePlansResponse(
                userChallengePlansResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break
          case 'challenge_os_results':
            const challengeOsResultsResponse =
              await ImportService.challengeUnitResults(importPrepareParams)

            if (challengeOsResultsResponse.data.success) {
              const response = getChallengeOsResultsResponse(
                challengeOsResultsResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break
          case 'challenge_sp_results':
            const challengeSpResultsResponse =
              await ImportService.challengeUnitResults(importPrepareParams)

            if (challengeSpResultsResponse.data.success) {
              const response = getChallengeSpResultsResponse(
                challengeSpResultsResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'user_challenge_results':
            const userChallengeResultsResponse =
              await ImportService.userChallengeResults(importPrepareParams)

            if (userChallengeResultsResponse.data.success) {
              const response = getUserChallengeResultsResponse(
                userChallengeResultsResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'user_plans':
            const userPlansResponse = await ImportService.userPlans(
              importPrepareParams,
            )

            if (userPlansResponse.data.success) {
              const response = getUserPlansResponse(
                userPlansResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'store_period':
            const storePeriodsResponse = await ImportService.storePeriods(
              importPrepareParams,
            )

            if (storePeriodsResponse.data.success) {
              const response = getStorePeriodsResponse(
                storePeriodsResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'loyalty_award_confirmations':
            const loyaltyAwardConfirmationsResponse =
              await ImportService.loyaltyAwardConfirmation(importPrepareParams)

            if (loyaltyAwardConfirmationsResponse.data.success) {
              const response = getLoyaltyAwardConfirmationsResponse(
                loyaltyAwardConfirmationsResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'users_points_operations':
            const usersPointsOperationsResponse =
              await ImportService.usersPointsOperations(importPrepareParams)

            if (usersPointsOperationsResponse.data.success) {
              const response = getUsersPointsOperationsResponse(
                usersPointsOperationsResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'store_plans':
            const storePlansResponse = await ImportService.storePlans(
              importPrepareParams,
            )

            if (storePlansResponse.data.success) {
              const response = getStorePlansResponse(
                storePlansResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'store_plan_results':
            const storePlanResultsResponse =
              await ImportService.storePlanResults(importPrepareParams)

            if (storePlanResultsResponse.data.success) {
              const response = getStorePlanResultsResponse(
                storePlanResultsResponse,
                'accept',
                'success',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'trade_action_users':
            const importActionUsersResponse =
              await ImportService.prepareActionUsers(importPrepareParams)

            if (importActionUsersResponse.data.success) {
              const response = getActionUsersResponse(
                importActionUsersResponse,
                'prepare',
                'warning',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'user_assets':
            const importAssetUsersResponse =
              await ImportService.prepareAssetUsers(importPrepareParams)

            if (importAssetUsersResponse.data.success) {
              const response = getAssetUsersResponse(
                importAssetUsersResponse,
                'prepare',
                'warning',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          case 'store_orders':
            const storeOrdersResponse = await ImportService.prepareOrders(
              importPrepareParams,
            )

            if (storeOrdersResponse.data.success) {
              const response = getOrdersResponse(
                storeOrdersResponse,
                'prepare',
                'warning',
                t,
              )
              if (response) {
                handleSetAcceptResponse(response)
              }
            } else {
              toast.error(t('messages.error.generalError'))
            }
            break

          default:
            break
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setAlertOpen(false)
        setImportPrepareParams(null)
        setSavingImport(false)
      }
    }
  }

  useEffect(() => {
    setAlertOpen(false)
    setFile(null)
    setDataObj([])
  }, [importParameters])

  useEffect(() => {
    let importData: any[] = []
    let BreakException = {}
    let fileError = {
      type: 'info' as AlertColor,
      message: '',
    }

    setAlertOpen(false)
    setAlertData(fileError)
    setImportPrepareParams(null)

    try {
      let uniqueArr: any = {}
      dataObj.forEach((element, i) => {
        // check if required headers are present
        if (i === 0) {
          importParameters.forEach((param) => {
            if (
              !param.nullable &&
              !param.columnName.includes(element[param.fieldName])
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.columnMissing', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (param.unique) {
              if (!uniqueArr.hasOwnProperty(param.fieldName)) {
                Object.assign(uniqueArr, {
                  [param.fieldName]: [],
                })
              }
            }
          })
        } else {
          importParameters.forEach((param) => {
            if (
              param.fieldType === 'string' &&
              param.length &&
              element[param.fieldName] &&
              element[param.fieldName].length > param.length
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.tooLongString', {
                  column: param.columnName[0],
                  length: param.length,
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (
              ['integer', 'number'].includes(param.fieldType) &&
              // !isStringInteger(element[param.fieldName])
              !isNumber(parseInt(element[param.fieldName]))
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.wrongColumnType', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (
              param.fieldType === 'date' &&
              param.dateFormat &&
              !moment(
                element[param.fieldName],
                param.dateFormat.toUpperCase(),
                true,
              ).isValid()
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.wrongDateFormat', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (!param.nullable && !element[param.fieldName]) {
              fileError = {
                type: 'error',
                message: t('import.messages.cellIsEmpty', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (param.unique) {
              if (uniqueArr.hasOwnProperty(param.fieldName)) {
                uniqueArr[param.fieldName].push(element[param.fieldName])
              }
            }
          })
        }

        let objectRow: any = {}
        importParameters.forEach((param) =>
          Object.assign(objectRow, {
            [param.fieldName]: element[param.fieldName] ?? null,
          }),
        )
        importData.push(objectRow)
      })

      for (const [key, valuesArr] of Object.entries(uniqueArr)) {
        const hasDuplicatedIds = findDuplicates(valuesArr as string[])
        if (hasDuplicatedIds.length > 0) {
          fileError = {
            type: 'error',
            message: t('import.messages.duplicatedIds', {
              column: importData[0][key],
              values: hasDuplicatedIds.join(', '),
            }),
          }
          throw BreakException
        }
      }
      importData.shift() // remove first el from arr
    } catch (e) {
      if (e !== BreakException) {
        throw e
      } else {
        importData = []
        setAlertOpen(true)
        setAlertData(fileError)
        setFileLoading(false)
      }
    } finally {
      if (importData.length > 0) {
        setAlertData({
          type: 'info' as AlertColor,
          message: t('import.messages.fileReadyToImport', {
            length: importData.length,
          }),
        })
        setImportPrepareParams({
          ...additionalParams,
          data: importData,
        })
        setAlertOpen(true)
        setFileLoading(false)
      }
    }
  }, [dataObj, t, file, importParameters, additionalParams])

  return (
    <>
      {importParameters && (
        <>
          <Stack>
            <FileUploader
              file={file}
              accept={{
                'text/csv': ['.csv'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  [],
                'application/vnd.ms-excel': [],
              }}
              handleAcceptedFiles={(files) => {
                onFileDrop(files[0] as File)
                setAlertOpen(false)
                setFileLoading(true)
              }}
            />
          </Stack>
          {fileLoading && (
            <Alert
              severity="info"
              sx={{ my: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {t('common.loadingFile')}
            </Alert>
          )}
          {alertOpen && (
            <Alert
              severity={alertData.type}
              sx={{ my: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {alertData.message}
            </Alert>
          )}
          {importPrepareParams && (
            <Stack
              className="buttons-container"
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              width="100%"
            >
              <PrimaryButton onClick={saveImport} disabled={savingImport}>
                {savingImport ? t('common.importing') : t('common.import')}
                {savingImport && (
                  <CircularProgress size={18} style={{ marginLeft: '1rem' }} />
                )}
              </PrimaryButton>
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default ImportPrepare

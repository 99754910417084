import axios from 'axios'
import {
  LoyaltyAddUserParams,
  LoyaltyAddUserResponse,
  LoyaltyEditUserParams,
  LoyaltyEditUserResponse,
  LoyaltyUserDeactivateResponse,
  LoyaltyUserDetailsResponse,
  LoyaltyUserGroupsResponse,
  LoyaltyUsersResponse,
  LoyaltyUserTypeCode,
  LoyaltyUserVisibilityResponse,
} from '../store/LoyaltyUser/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getLoyaltyUsers = async (
  companies: { id: number }[],
  users: { id: number }[],
  participantTypeCode: LoyaltyUserTypeCode,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  loginDateFrom: string | null,
  loginDateTo: string | null,
  isActive: boolean | null,
  isVisible: boolean | null,
): Promise<LoyaltyUsersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/participant/list',
    {
      companies,
      users,
      participantTypeCode,
      search,
      sortBy,
      sortOrder,
      per,
      page,
      loginDateFrom,
      loginDateTo,
      isActive,
      isVisible,
    },
    {
      headers: authHeader(),
    },
  )
}

const addLoyaltyUser = async (
  params: LoyaltyAddUserParams,
): Promise<LoyaltyAddUserResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/participant/create',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const editLoyaltyUser = async (
  params: LoyaltyEditUserParams,
): Promise<LoyaltyEditUserResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/participant/edit',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getParticipantDetails = async (
  participantId: number,
): Promise<LoyaltyUserDetailsResponse> => {
  return await axios.get(
    API_URL + `/api/admin/loyalty/participant/preview/${participantId}`,
    {
      headers: authHeader(),
    },
  )
}

const getGroupList = (): Promise<LoyaltyUserGroupsResponse> => {
  return axios.get(API_URL + '/api/admin/loyalty/group/list-simple', {
    headers: authHeader(),
  })
}

const userDeactivate = async (
  participants: { id: number }[],
): Promise<LoyaltyUserDeactivateResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/participant/deactivate',
    {
      participants,
    },
    {
      headers: authHeader(),
    },
  )
}

const userVisibility = async (
  participants: { id: number }[],
): Promise<LoyaltyUserVisibilityResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/participant/visible',
    {
      participants,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getLoyaltyUsers,
  addLoyaltyUser,
  editLoyaltyUser,
  getParticipantDetails,
  getGroupList,
  userDeactivate,
  userVisibility,
}

export default exportedObject

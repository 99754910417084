import { AlertColor } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import { ImportParameterColumn } from '../../../../store/Import/types'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import ImportAccept from './ImportAccept'
import ImportColumns from './ImportColumns'
import ImportPrepare from './ImportPrepare'
import ImportService from '../../../../services/import.service'

type GenericImportProps = {
  name: string
  param1?: string | number
  param2?: string | number
}

const GenericImport: React.FC<GenericImportProps> = ({
  name,
  param1,
  param2,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [importParameters, setImportParameters] = useState<
    ImportParameterColumn[]
  >([])
  const [additionalParams, setAdditionalParams] = useState({})
  const [acceptResponse, setAcceptResponse] = useState<{
    message: {
      type: AlertColor
      message: string
      hasSecondStep: boolean
    }
    additionalInfo?: React.ReactNode
  } | null>(null)

  useEffect(() => {
    if (param1 && !param2) {
      switch (name) {
        case 'store_plans':
        case 'store_plan_results':
        case 'user_plans':
          setAdditionalParams({
            planId: param1,
          })
          break
        case 'user_challenge_results':
        case 'challenge_os_results':
        case 'challenge_sp_results':
          setAdditionalParams({
            challengeId: param1,
          })
          break
        case 'store_period':
          setAdditionalParams({
            periodId: param1,
          })
          break
        case 'trade_action_users':
          setAdditionalParams({
            actionId: param1,
          })
          break
        case 'user_assets':
          setAdditionalParams({
            assetId: param1,
          })
          break
        case 'loyalty_award_confirmations':
          setAdditionalParams({
            periodId: param1,
          })

          break
        default:
          break
      }
    }

    if (param1 && param2) {
      switch (name) {
        case 'users_points_operations':
          setAdditionalParams({
            operationTypeId: param1,
            periodId: param2,
          })
          break
        default:
          break
      }
    }
  }, [param1, param2, name])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const importParametersResponse =
          await ImportService.getImportParameters(name)
        if (importParametersResponse.data) {
          setImportParameters(importParametersResponse.data.columns)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    setAcceptResponse(null)
  }, [t, name])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <ImportColumns importParameters={importParameters} />
          <ImportPrepare
            importParameters={importParameters}
            importName={name}
            handleSetAcceptResponse={setAcceptResponse}
            additionalParams={additionalParams}
          />
          <ImportAccept
            importName={name}
            acceptResponse={acceptResponse}
            handleSetAcceptResponse={setAcceptResponse}
            additionalParams={additionalParams}
          />
        </>
      )}
    </>
  )
}

export default GenericImport

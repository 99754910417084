import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import CompanyService from '../../../../services/company.service'
import UserService from '../../../../services/user.service'
import LoyaltyProtocolsService from '../../../../services/loyaltyProtocols.service'
import { useTranslation } from 'react-i18next'
import LoyaltyProtocolsToolbar from '../partials/LoyaltyProtocolsToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { User } from '../../../../store/Auth/types'
import { LoyaltyProtocol } from '../../../../store/LoyaltyProtocols/types'
import { CellProps, Column, ColumnInstance, Row } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
  actualDate,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import IndeterminateCheckbox from '../../../Table/IndeterminateCheckbox'
import DownloadIcon from '@mui/icons-material/Download'
import { UserFilter } from '../../../../store/User/types'
import LoyaltyPeriodService from '../../../../services/loyaltyPeriod.service'
import { LoyaltyPeriod } from '../../../../store/LoyaltyPeriod/types'
import ImportDialog from '../../../shared/ImportDialog'
type LoyaltyUsersListProps = {
  user: User
}

const LoyaltyUsersList: FunctionComponent<LoyaltyUsersListProps> = ({
  user,
}) => {
  const tableName = `loyaltyProtocols`

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredProtocolsList, setFilteredProtocolsList] = useState<
    LoyaltyProtocol[]
  >([])
  const [periods, setPeriods] = useState<LoyaltyPeriod[]>([])
  const [periodValue, setPeriodValue] = useState<string>('all')

  const [searchValue, setSearchValue] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const [statusValue, setStatusValue] = useState<string>('all')
  const [downloadedValue, setDownloadedValue] = useState<string>('all')

  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])

  const [users, setUsers] = useState<UserFilter[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Option[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])

  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])

  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [protocolId, setProtocolId] = useState<number>()
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const [selectedRows, setSelectedRows] = useState<Row<object>[]>([])
  const [refresh, setRefresh] = useState(false)

  const [importName, setImportName] = useState<string | null>(null)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [importParam, setImportParam] = useState<number>()
  const [periodImportValue, setPeriodImportValue] = useState<string>('')
  const [importTitle, setImportTitle] = useState<string>()

  const handleDownloadZipFile = async (
    selectedRows: Row<any>[],
    fileName: string,
    fileType: string,
  ) => {
    try {
      const zipCode = await LoyaltyProtocolsService.getProtocolPdfsZip(
        selectedRows.map((row) => ({ id: row.original.id })),
      )
      const url = window.URL.createObjectURL(
        new Blob([zipCode.data], { type: fileType }),
      )

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.zip`)
      document.body.appendChild(link)
      link.click()
      setRefresh((prevState) => !prevState)
    } catch (error) {
      errorHandler(error, t)
    }
  }

  const generateTableColumns = useCallback(
    (users: LoyaltyProtocol[]) => {
      const columns = []
      columns.push(
        {
          id: 'selection',
          Header: ({ toggleRowSelected, isAllPageRowsSelected, page }: any) => {
            const modifiedOnChange = (
              event: React.ChangeEvent<HTMLInputElement>,
            ) => {
              page.forEach((row: any) => {
                //check each row if it is not disabled
                row.original.statusName === 'SIGNED' &&
                  toggleRowSelected(row.id, event.currentTarget.checked)
              })
            }

            //Count number of selectable and selected rows in the current page
            //to determine the state of select all checkbox
            let selectableRowsInCurrentPage = 0
            let selectedRowsInCurrentPage = 0
            page.forEach((row: any) => {
              row.isSelected && selectedRowsInCurrentPage++
              row.original.statusName === 'SIGNED' &&
                selectableRowsInCurrentPage++
            })

            //If there are no selectable rows in the current page
            //select all checkbox will be disabled -> see page 2
            const disabled = selectableRowsInCurrentPage === 0
            const checked =
              (isAllPageRowsSelected ||
                selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
              !disabled

            return (
              <div>
                <IndeterminateCheckbox
                  // @ts-ignore
                  onChange={modifiedOnChange}
                  checked={checked}
                  disabled={disabled}
                />
              </div>
            )
          },
          width: 40,
          Cell: ({ row }: CellProps<any>) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                // @ts-ignore
                disabled={row.original.statusName !== 'SIGNED'}
              />
            </div>
          ),
          sticky: 'left',
        },
        {
          accessor: 'periodName',
          Header: t('pages.loyaltyProtocols.table.periodName').toString(),

          width: 160,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'participantId',
          Header: t('pages.loyaltyProtocols.table.participantId').toString(),
          width: 130,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'participantUsername',
          Header: t(
            'pages.loyaltyProtocols.table.participantUsername',
          ).toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeCentralId',
          Header: t('pages.loyaltyProtocols.table.storeCentralId').toString(),
          width: 125,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeName',
          Header: t('pages.loyaltyProtocols.table.storeName').toString(),
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'description1',
          Header: t('pages.loyaltyProtocols.table.description1').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'description2',
          Header: t('pages.loyaltyProtocols.table.description2').toString(),
          width: 200,
          Cell: (params: any) => params.value,
        },

        {
          accessor: 'description3',
          Header: t('pages.loyaltyProtocols.table.description3').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },

        {
          accessor: 'description4',
          Header: t('pages.loyaltyProtocols.table.description4').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },

        {
          accessor: 'description5',
          Header: t('pages.loyaltyProtocols.table.description5').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },

        {
          accessor: 'authorFirstname',
          Header: t('pages.loyaltyProtocols.table.authorFirstname').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },

        {
          accessor: 'authorLastname',
          Header: t('pages.loyaltyProtocols.table.authorLastname').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'signature',
          Header: t('pages.loyaltyProtocols.table.signature').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },

        {
          accessor: 'signDate',
          Header: t('pages.loyaltyProtocols.table.signDate').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'size',
          Header: t('pages.loyaltyProtocols.table.size').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'statusName',
          Header: t('pages.loyaltyProtocols.table.statusName').toString(),
          width: 130,
          Cell: (params: any) =>
            params.row.original.statusName === 'SIGNED'
              ? t('pages.loyaltyProtocols.table.confirmed')
              : t('pages.loyaltyProtocols.table.notConfirmed'),
        },
        {
          accessor: 'isDownloaded',
          Header: t('pages.loyaltyProtocols.table.isDownloaded').toString(),
          width: 70,
          Cell: (params: any) =>
            params.row.original.isDownloaded ? t('common.yes') : t('common.no'),
        },
        {
          accessor: 'actions',
          Header: t('pages.loyaltyProtocols.table.actions').toString(),
          width: 55,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <>
              <Grid container>
                <Grid
                  item
                  sx={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip
                    title={`${t('pages.loyaltyProtocols.table.download')}`}
                  >
                    <IconButton
                      aria-label="edit"
                      size="small"
                      style={{
                        padding: 0,
                        marginRight: '5px',
                        opacity:
                          params.row.original.statusName !== 'SIGNED' ? 0.3 : 1,
                      }}
                      onClick={() => setProtocolId(params.row.original.id)}
                      disabled={
                        params.row.original.statusName !== 'SIGNED'
                          ? true
                          : false
                      }
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ),
        },
      )
      return columns
    },
    [t],
  )

  useEffect(() => {
    if (protocolId) {
      const handleDownloadPdfFile = async () => {
        try {
          const pdfCode = await LoyaltyProtocolsService.getProtocolPdfFile(
            protocolId,
          )
          const url = window.URL.createObjectURL(
            new Blob([pdfCode.data], { type: 'application/pdf' }),
          )

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `protocol${protocolId}.pdf`)
          document.body.appendChild(link)
          link.click()
          setRefresh((prevState) => !prevState)
        } catch (error) {
          errorHandler(error, t)
        }
      }
      handleDownloadPdfFile()
    }
  }, [t, protocolId])

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          const companiesState = getTableState(tableName, 'companies')
          if (companiesState) {
            setSelectedCompanies(JSON.parse(companiesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }

        const userListResponse = await UserService.getUserListFilter()

        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )

          setUsers(userListResponse.data.users)
          setSelectedUsers(multiSelectOptions)
          setFilteredUsers(multiSelectOptions)
        }

        const periodListResponse = await LoyaltyPeriodService.getPeriodList()
        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          const activePeriod = periodListResponse.data.periods.find(
            (period) => period.isActive === true,
          )

          setPeriodValue(
            activePeriod && activePeriod.id
              ? activePeriod.id.toString()
              : 'all',
          )
          setPeriodImportValue(
            activePeriod && activePeriod.id ? activePeriod.id.toString() : '1',
          )
        }
        const searchState = getTableState(tableName, 'search')
        const statusState = getTableState(tableName, 'status')
        const downloadedState = getTableState(tableName, 'downloaded')
        const periodState = getTableState(tableName, 'period')

        if (searchState) {
          setSearchText(searchState)
          setSearchValue(searchState)
        } else {
          setSearchText('')
          setSearchValue('')
        }

        if (statusState) {
          setStatusValue(statusState)
        } else {
          setStatusValue('all')
        }

        if (downloadedState) {
          setDownloadedValue(downloadedState)
        } else {
          setDownloadedValue('all')
        }

        if (periodState) {
          setPeriodValue(periodState)
        } else {
          setPeriodValue('all')
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }

    fetchFiltersData()
  }, [t, tableName])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)
          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const page = ++pageIndex
            const protocolsResponse =
              await LoyaltyProtocolsService.getLoyaltyProtocols(
                selectedCompanies.map((company) => {
                  return {
                    id: company.value,
                  }
                }),
                selectedUsers.map((user) => ({ id: user.value })),
                periodValue === 'all'
                  ? periods.map((period) => ({ id: period.id }))
                  : [{ id: parseInt(periodValue) }],
                searchValue,
                sortColumn,
                sortDirection,
                pageSize,
                page,
                statusValue === 'all'
                  ? null
                  : statusValue === '0'
                  ? false
                  : true,
                downloadedValue === 'all'
                  ? null
                  : downloadedValue === '0'
                  ? false
                  : true,
              )

            if (protocolsResponse.data.protocols) {
              setTableColumns(
                generateTableColumns(protocolsResponse.data.protocols),
              )

              setFilteredProtocolsList(protocolsResponse.data.protocols)

              setTotalCount(protocolsResponse.data.totalCount)
              setPageCount(
                Math.ceil(protocolsResponse.data.totalCount / pageSize),
              )
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredProtocolsList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [
      t,
      selectedCompanies,
      searchValue,
      generateTableColumns,
      refresh,
      statusValue,
      downloadedValue,
      selectedUsers,
      periodValue,
      periods,
    ],
  )
  const handleImportDialogClickOpen = (periodId: string) => {
    setImportParam(parseInt(periodId!))
    setImportName('loyalty_award_confirmations')
    setImportDialogOpen(true)
    setImportTitle(
      t('pages.loyaltyProtocols.importDialog.title', {
        periodName: periods.find((p) => p.id === parseInt(periodId!))?.name,
      }),
    )
  }

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
  }
  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'companies', JSON.stringify(companies))
    setSelectedCompanies(companies)
  }

  useEffect(() => {
    const filterUsers = () => {
      const filteredUsersForMultiselect = users
        .filter((user) =>
          selectedCompanies
            .map((company) => company.value)
            .includes(user.companyId),
        )
        .map((u) => ({
          value: u.id,
          label: `${u.lastname} ${u.firstname}`,
        }))
      setFilteredUsers(filteredUsersForMultiselect)
      setSelectedUsers(filteredUsersForMultiselect)
    }
    filterUsers()
  }, [selectedCompanies, users])

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const protocolsResponse =
        await LoyaltyProtocolsService.getLoyaltyProtocols(
          selectedCompanies.map((company) => {
            return {
              id: company.value,
            }
          }),
          selectedUsers.map((user) => ({ id: user.value })),
          periodValue === 'all'
            ? periods.map((period) => ({ id: period.id }))
            : [{ id: parseInt(periodValue) }],
          searchValue,
          downloadSortBy,
          downloadSortOrder,
          100000,
          1,
          statusValue === 'all' ? null : statusValue === '0' ? false : true,
          downloadedValue === 'all'
            ? null
            : downloadedValue === '0'
            ? false
            : true,
        )

      const dataProtocols = protocolsResponse.data.protocols
      if (dataProtocols) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'periodName',
            'participantId',
            'participantUsername',
            'storeCentralId',
            'storeName',
            'description1',
            'description2',
            'description3',
            'description4',
            'description5',
            'authorFirstname',
            'authorLastname',
            'signature',
            'signDate',
            'size',
            'statusName',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredProtocolsData = dataProtocols.map((protocol) => {
          protocol.statusName =
            protocol.statusName === 'SIGNED'
              ? t('excel.loyaltyProtocols.confirmed').toString()
              : t('excel.loyaltyProtocols.notConfirmed').toString()
          protocol.isDownloaded = protocol.isDownloaded
            ? t('excel.loyaltyProtocols.confirmed').toString()
            : t('excel.loyaltyProtocols.notConfirmed').toString()
          return pick(protocol, visibleColumns)
        })

        const translatedHeaders = {
          periodName: t('excel.loyaltyProtocols.periodName'),
          participantId: t('excel.loyaltyProtocols.participantId'),
          participantUsername: t('excel.loyaltyProtocols.participantUsername'),
          storeCentralId: t('excel.loyaltyProtocols.storeCentralId'),
          storeName: t('excel.loyaltyProtocols.storeName'),
          description1: t('excel.loyaltyProtocols.description1'),
          description2: t('excel.loyaltyProtocols.description2'),
          description3: t('excel.loyaltyProtocols.description3'),
          description4: t('excel.loyaltyProtocols.description4'),
          description5: t('excel.loyaltyProtocols.description5'),
          authorFirstname: t('excel.loyaltyProtocols.authorFirstname'),
          authorLastname: t('excel.loyaltyProtocols.authorLastname'),
          signature: t('excel.loyaltyProtocols.signature'),
          signDate: t('excel.loyaltyProtocols.signDate'),
          size: t('excel.loyaltyProtocols.size'),
          statusName: t('excel.loyaltyProtocols.statusName'),
        }

        const headers = [
          Object.keys(filteredProtocolsData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredProtocolsData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredProtocolsData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding={'5px'}
              style={{
                border: '1px solid #ccc',
                borderRadius: 5,
              }}
            >
              <PrimaryButton
                variant="contained"
                onClick={() => handleImportDialogClickOpen(periodImportValue)}
                style={{ marginRight: 5 }}
              >
                {t('pages.loyaltyProtocols.import')}
              </PrimaryButton>
              {t('pages.loyaltyProtocols.forPeriod')}
              <FormControl size="small">
                <Select
                  id="period-select"
                  onChange={(event: SelectChangeEvent) => {
                    setPeriodImportValue(event.target.value)
                  }}
                  value={periodImportValue}
                  defaultValue={periodImportValue}
                  style={{ marginLeft: 5 }}
                >
                  {periods.map((period) => (
                    <MenuItem value={period.id} key={period.id}>
                      {period.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('loyaltyProtocols')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <LoyaltyProtocolsToolbar
            user={user}
            companies={companies}
            selectedCompanies={selectedCompanies}
            users={filteredUsers}
            selectedUsers={selectedUsers}
            periods={periods}
            periodValue={periodValue}
            value={searchText}
            statusValue={statusValue}
            downloadedValue={downloadedValue}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
              setSearchText(searchValue)
              setTableState(tableName, 'search', searchValue)
            }}
            setSelectedCompanies={(companies) => {
              handleSelectedCompaniesChange(companies)
              setSkipPageReset(false)
            }}
            setSelectedUsers={(users: Option[]) => {
              setSelectedUsers(users)
              setSkipPageReset(false)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            filterStatus={(event: SelectChangeEvent) => {
              setStatusValue(event.target.value)
              setTableState(tableName, 'status', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            filterDownloaded={(event: SelectChangeEvent) => {
              setDownloadedValue(event.target.value)
              setTableState(tableName, 'downloaded', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setSkipPageReset(false)
              setControlledPageIndex(0)
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
            }}
          />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            style={{ marginBottom: '-22px' }}
          >
            <Button
              size="small"
              endIcon={<DownloadIcon />}
              style={{ textTransform: 'none', marginRight: 5 }}
              disabled={selectedRows.length === 0}
              onClick={() =>
                handleDownloadZipFile(
                  selectedRows,
                  `protocolsPack${actualDate()}`,
                  'application/zip',
                )
              }
            >
              {t('pages.loyaltyProtocols.table.downloadSelectedProtocols')}
            </Button>
          </Stack>
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredProtocolsList}
            height="calc(100vh - 320px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'description1',
              'description2',
              'description3',
              'description4',
              'description5',
            ]}
            toggleVisibility={setColumnsVisibility}
            handleSelectedRows={setSelectedRows}
          />
          {periodImportValue && importName && (
            <ImportDialog
              open={importDialogOpen}
              handleClose={handleImportDialogClose}
              name={importName}
              param1={importParam}
              title={importTitle}
            />
          )}
        </>
      )}
    </>
  )
}

export default LoyaltyUsersList

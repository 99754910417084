import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import {
  LoyaltyEditUserErrors,
  LoyaltyEditUserParams,
} from '../../../../store/LoyaltyUser/types'
import LoyaltyUserService from '../../../../services/loyaltyUser.service'
import { validateEmail } from '../../../../helpers/utils'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'

type LoyaltyUserEditDialogProps = {
  participantId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const LoyaltyUserEditDialog: React.FunctionComponent<
  LoyaltyUserEditDialogProps
> = ({ participantId, open, handleClose }) => {
  const DisabledTextField = styled(TextField)(({ theme }) => ({
    input: {
      backgroundColor: '#e1e1e1',
      borderRadius: '4px',
    },
  }))

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)

  const [centralId, setCentralId] = useState<string>('')
  const [storeCentralId, setStoreCentralId] = useState<string>('')
  const [storeName, setStoreName] = useState<string>('')
  const [firstname, setFirstname] = useState<string | null>('')
  const [lastname, setLastname] = useState<string | null>('')
  const [email, setEmail] = useState<string | null>('')
  const [username, setUsername] = useState<string>('')
  const [description, setDescription] = useState<string | null>('')
  const [descriptionLength, setDescriptionLength] = useState<number>(0)
  const [additionalDescriptionLength, setAdditionalDescriptionLength] =
    useState<number>(0)
  const [additionalDescription, setAdditionalDescription] = useState<
    string | null
  >('')
  const [resendInvitationMail, setResendInvitationMail] =
    useState<boolean>(false)

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: LoyaltyEditUserErrors = {
    participantId: defaultError,
    firstname: defaultError,
    lastname: defaultError,
    email: defaultError,
    username: defaultError,
    description: defaultError,
    additionalDescription: defaultError,
    resendInvitationMail: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<LoyaltyEditUserErrors>(defaultFormErrors)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleFirstnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFirstname(event.target.value)
  }

  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= 100) {
      setDescription(event.target.value)
      setDescriptionLength(event.target.value.length)
    }
  }
  const handleAdditionalDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= 255) {
      setAdditionalDescription(event.target.value)
      setAdditionalDescriptionLength(event.target.value.length)
    }
  }

  const handleResendInvitationMailChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setResendInvitationMail(event.target.checked)
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setCentralId('')
    setStoreCentralId('')
    setStoreName('')
    setFirstname('')
    setLastname('')
    setEmail('')
    setUsername('')
    setDescription('')
    setAdditionalDescription('')
    setResendInvitationMail(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // get participant details
        const participantDetailsResponse =
          await LoyaltyUserService.getParticipantDetails(participantId)
        if (participantDetailsResponse.data) {
          setCentralId(participantDetailsResponse.data.participantCentralId)
          setStoreCentralId(participantDetailsResponse.data.storeCentralId)
          setStoreName(participantDetailsResponse.data.storeCentralId)
          setFirstname(participantDetailsResponse.data.firstname)
          setLastname(participantDetailsResponse.data.lastname)
          setEmail(participantDetailsResponse.data.email)
          setUsername(participantDetailsResponse.data.username || '')
          setDescription(participantDetailsResponse.data.description)
          setAdditionalDescription(
            participantDetailsResponse.data.additionalDescription,
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [open, participantId, t])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const userErrors = defaultFormErrors

    if (email && !validateEmail(email)) {
      setFormErrors({
        ...userErrors,
        email: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyEditUserDialog.errors.emailInvalid',
          ),
        },
      })
      return
    } else if (username.trim() === '') {
      setFormErrors({
        ...userErrors,
        username: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyEditUserDialog.errors.required',
          ),
        },
      })
      return
    } else if (!email && resendInvitationMail) {
      setFormErrors({
        ...userErrors,
        email: {
          error: true,
          message: t(
            'pages.loyaltyUsersprt.loyaltyAddUserDialog.errors.emailForPassword',
          ),
        },
      })
      return
    }

    const formParams: LoyaltyEditUserParams = {
      participantId,
      firstname: firstname?.trim() === '' ? null : firstname,
      lastname: lastname?.trim() === '' ? null : lastname,
      email: email?.trim() === '' ? null : email,
      username,
      description: description?.trim() === '' ? null : description,
      additionalDescription:
        additionalDescription?.trim() === '' ? null : additionalDescription,
      resendInvitationMail,
    }

    try {
      setSaving(true)
      const editParticipantResponse = await LoyaltyUserService.editLoyaltyUser(
        formParams,
      )

      if (editParticipantResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.loyaltyUsersprt.loyaltyEditUserDialog.title')}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyEditUserDialog.participantId',
                    )}
                  </label>
                  <DisabledTextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={participantId}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyEditUserDialog.centralId')}
                  </label>
                  <DisabledTextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={centralId}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyEditUserDialog.storeCentralId',
                    )}
                  </label>
                  <DisabledTextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={storeCentralId}
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyEditUserDialog.storeName')}
                  </label>
                  <DisabledTextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={storeName}
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyEditUserDialog.firstname')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={formErrors.firstname.error}
                    helperText={
                      formErrors.firstname.error && formErrors.firstname.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyEditUserDialog.lastname')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={lastname}
                    onChange={handleLastnameChange}
                    error={formErrors.lastname.error}
                    helperText={
                      formErrors.lastname.error && formErrors.lastname.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyEditUserDialog.email')} *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={email}
                    onChange={handleEmailChange}
                    error={formErrors.email.error}
                    helperText={
                      formErrors.email.error && formErrors.email.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.loyaltyUsersprt.loyaltyEditUserDialog.username')}{' '}
                    *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={username}
                    onChange={handleUsernameChange}
                    error={formErrors.username.error}
                    helperText={
                      formErrors.username.error && formErrors.username.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyEditUserDialog.description',
                    )}
                  </label>
                  <TextField
                    multiline
                    rows={2}
                    value={description}
                    onChange={handleDescriptionChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <div className="character-amount">{descriptionLength}/100</div>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.loyaltyUsersprt.loyaltyEditUserDialog.additionalDescription',
                    )}
                  </label>
                  <TextField
                    multiline
                    rows={4}
                    value={additionalDescription}
                    onChange={handleAdditionalDescriptionChange}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </FormControl>
                <div className="character-amount">
                  {additionalDescriptionLength}/255
                </div>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={resendInvitationMail}
                      onChange={handleResendInvitationMailChange}
                    />
                  }
                  label={t(
                    'pages.loyaltyUsersprt.loyaltyEditUserDialog.resetPassword',
                  )}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default LoyaltyUserEditDialog

import Map0Img from '../../assets/images/game/map/phone_preview/0.png'
import Map1Img from '../../assets/images/game/map/phone_preview/1.png'
import Map2Img from '../../assets/images/game/map/phone_preview/2.png'
import Map3Img from '../../assets/images/game/map/phone_preview/3.png'
import Map4Img from '../../assets/images/game/map/phone_preview/4.png'
import Map5Img from '../../assets/images/game/map/phone_preview/5.png'
import Map6Img from '../../assets/images/game/map/phone_preview/6.png'
import Map7Img from '../../assets/images/game/map/phone_preview/7.png'
import Map8Img from '../../assets/images/game/map/phone_preview/8.png'
import Map9Img from '../../assets/images/game/map/phone_preview/9.png'
import Map10Img from '../../assets/images/game/map/phone_preview/10.png'
import Map11Img from '../../assets/images/game/map/phone_preview/11.png'
import Map12Img from '../../assets/images/game/map/phone_preview/12.png'
import Map13Img from '../../assets/images/game/map/phone_preview/13.png'
import Map14Img from '../../assets/images/game/map/phone_preview/14.png'
import Map15Img from '../../assets/images/game/map/phone_preview/15.png'
import Map16Img from '../../assets/images/game/map/phone_preview/16.png'
import Map17Img from '../../assets/images/game/map/phone_preview/17.png'
import Map18Img from '../../assets/images/game/map/phone_preview/18.png'

export const backgroundsArray: string[] = [
  Map0Img,
  Map1Img,
  Map2Img,
  Map3Img,
  Map4Img,
  Map5Img,
  Map6Img,
  Map7Img,
  Map8Img,
  Map9Img,
  Map10Img,
  Map11Img,
  Map12Img,
  Map13Img,
  Map14Img,
  Map15Img,
  Map16Img,
  Map17Img,
  Map18Img,
]

import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { downloadXLSX, thousandsSeparator } from '../../../../../helpers/utils'
import { ImportStorePlanResultResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getStorePlanResultsResponse = (
  importResponse: ImportStorePlanResultResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (
    importResponse.data.updatedResults &&
    importResponse.data.skippedResults
  ) {
    const updatedResults = importResponse.data.updatedResults
    const skippedResults = importResponse.data.skippedResults
    const updatedResultsSummary = importResponse.data.updatedResultsSummary
    const skippedResultsSummary = importResponse.data.skippedResultsSummary

    const renderAdditionalInfo = () => {
      return (
        <>
          {skippedResults.length > 0 && (
            <div>
              <SecondaryButton
                onClick={() =>
                  downloadXLSX(
                    skippedResults.map((result) => ({
                      storeCentralId: result,
                    })),
                    t(
                      'import.messages.storePlanResults.skippedResultsFilename',
                    ),
                  )
                }
                size="small"
                style={{ marginBottom: '10px' }}
              >
                {t('import.messages.storePlanResults.downloadSkippedResults')}
              </SecondaryButton>
            </div>
          )}
        </>
      )
    }

    return {
      message: {
        type: alertType,
        message: t(`import.messages.storePlanResults.${messageType}Message`, {
          updatedResultsSummary: thousandsSeparator(
            updatedResultsSummary || 0,
            true,
          ),
          skippedResultsSummary: thousandsSeparator(
            skippedResultsSummary || 0,
            true,
          ),
          updatedResults: updatedResults.length,
          skippedResults: skippedResults.length,
        }),
        hasSecondStep: false,
      },
      additionalInfo: renderAdditionalInfo(),
    }
  }
  return null
}
